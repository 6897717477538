import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";

// Import Images
import { fetchData } from "../../services/fetchData";

const MegaMenu = ({ t }) => {
  const [megaMenu, setMegaMenu] = useState(false);
  const [data, setData] = useState(null);

  const fetchDataAsync = async () => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];
      
      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1
        },
      };
      if (authID.type == 2) { // tenant id
        payload.whereCondition.id = authID.tenant_id
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code === 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataAsync();
  }, []);

  const handelSetTenatId = (id) => {
    localStorage.setItem("tenantId", id);
    setMegaMenu(!megaMenu);
    window.location.reload();

  };

  return (
    <React.Fragment>
      <Dropdown
        className="dropdown-mega d-none d-lg-block ms-2"
        isOpen={megaMenu}
        toggle={() => setMegaMenu(!megaMenu)}>
        <DropdownToggle
          tag="button"
          type="button"
          caret
          className="btn header-item waves-effect">
          {t("Select Tenant")} <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-megamenu">
          <Row>
            <Col sm={8}>
              <Row>
                <Col md={4}>
                  <h5 className="font-size-14 mt-0">
                    {t("Tenant Company Name")}
                  </h5>
                  <ul className="list-unstyled megamenu-list">
                    {data &&
                      data.map((tenant) => (
                        <li
                          key={tenant.id}
                          onClick={() => handelSetTenatId(tenant.id)}>
                          <Link to="#">{tenant.tenant_company_name}</Link>
                        </li>
                      ))}
                  </ul>
                </Col>
              </Row>
            </Col>
          
          </Row>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(MegaMenu);
