import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/fetchData";

const breadcrumbItems = [];

const DesignationList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    try {
      let tenantId = localStorage.getItem("tenantId");
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      authID = authID[0];
      const payload = {
        modelName: "designation_masters",
        relations: [
          {
            module: "tenant_masters",
            moduleas: "tenant_masters",
          },
        ],
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        payload.whereCondition.id = tenantId;
      }

      if (authID.type == 2) {
        payload.whereCondition.tenant_id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Tenant Name",
        accessor: (row) =>
          row.tenant_masters && row.tenant_masters.tenant_company_name
            ? row.tenant_masters.tenant_company_name
            : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Designation Name",
        accessor: (row) =>
          row.designation_name && row.designation_name
            ? row.designation_name
            : "-",

        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="primary"
            onClick={() => handleEditClick(row.original.id)} // Assuming `id` is the unique identifier
          >
            <i class="fas fa-edit"></i>
          </Button>
        ),
      },
    ],
    []
  );

  const handleClickAdd = () => {
    navigate("/designation-create");
  };

  const handleEditClick = (id) => {
    navigate(`/designation-create/${id}`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Manage Designation List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Manage Designation
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DesignationList;
