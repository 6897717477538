import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Input,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { dropdownData, fetchData } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const CountryCreate = () => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const [time, setTime] = useState("");
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    country_name: "",
    timezone: "",
    country_code: "",
    is_active: 1,
    created_by: 1,
  });

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "country_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          const data = responseData.data[0];
          setFormData(data);
          const timeString = data.timezone.split("T")[1].substring(0, 5);
          setTime(timeString);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const convertToDateTime = (e) => {
    const { value } = e.target;
    setTime(value);

    // Get the current date
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Combine date and time to form a complete datetime string
    const combinedDateTimeString = `${year}-${month}-${day}T${value}:00.000Z`; // Assuming UTC timezone
    // Convert to JavaScript Date object (Optional)
    // const combinedDateTime = new Date(combinedDateTimeString);

    setFormData((prevState) => ({
      ...prevState,
      timezone: combinedDateTimeString,
    }));

    return combinedDateTimeString;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.country_name ||
      !formData.country_code ||
      !formData.timezone
    ) {
      return toast.warning("All fields are required");
    }

    try {
      setLoadingData(true);
      var payload = {
        modelName: "country_masters",
        inputData: formData,
      };
      if (id) {
        payload.id = id;
      }
      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Country saved successfully!");
        navigate("/country-list");
      } else {
        toast.error("Error while saving Country!");
        navigate("/country-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/country-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE COUNTRY"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Country Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="country_name"
                                placeholder="Country name"
                                type="text"
                                errorMessage="Enter Country Name"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleTextChange}
                                value={formData.country_name}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Time
                                <Asterisk />
                              </Label>
                              <AvField
                                name="timezone"
                                type="time"
                                errorMessage="Enter Time"
                                onChange={convertToDateTime}
                                value={time}
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Country Code</Label>
                              <div
                                className="input-container"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}>
                                <span
                                  className="input-prefix"
                                  style={{
                                    position: "absolute",
                                    alignItems: "center",
                                    padding: "0 8px",
                                  }}>
                                  +
                                </span>
                                <Input
                                  className="form-control input-with-prefix pl-10"
                                  name="country_code"
                                  type="number"
                                  errorMessage="Enter Country Code"
                                  onChange={handleTextChange}
                                  value={formData.country_code}
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^[1-9]\\d*$",
                                      errorMessage:
                                        "Please enter a positive value greater than zero",
                                    },
                                  }}
                                  style={{ paddingLeft: "15px" }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CountryCreate;
