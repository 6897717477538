import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  downloadBlobData,
  dropdownData,
  fetchData,
  image_url,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";

const AcademicCategoryCreate = ({ router }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [breadcrumbItems] = useState([]);

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [tenantError, setTenantError] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [fetchedData, setFetchedData] = useState(null);
  const [iconImages, setIconImages] = useState([]);
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] = useState(
    Array(iconImages.length).fill(false)
  );
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(
    Array(iconImages.length).fill(false)
  );

  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: "",
      type: 1,
      category_name: "",
      is_deleted: 0,
      category_icon: [
        {
          type: 1,
          icon: "",
        },
        {
          type: 2,
          icon: "",
        },
        {
          type: 3,
          icon: "",
        },
      ],
      sequence: "",
      is_active: 1,
    },

    uploadImage: [
      {
        imagePath: "",
        folderPath: "users/category_master",
        field_name: "category_icon[0].icon",
      },
      {
        imagePath: "",
        folderPath: "users/category_master",
        field_name: "category_icon[1].icon",
      },
      {
        imagePath: "",
        folderPath: "users/category_master",
        field_name: "category_icon[2].icon",
      },
    ],
  });

  useEffect(() => {
    getData(id);
  }, []);

  const mapTypesToNumbers = (data) => {
    const typeMap = {
      Small: 1,
      Big: 2,
      Other: 3,
    };

    if (data && data.length > 0) {
      return data.map((item) => ({
        type: typeMap[item.type] || item.type,
        icon: item.icon,
      }));
    }
  };

  useEffect(() => {
    if (fetchedData) {
      let mappedData;
      if (fetchedData.category_icon) {
        mappedData = {
          ...fetchedData,
          category_icon: mapTypesToNumbers(fetchedData.category_icon),
        };
      } else {
        mappedData = {
          ...fetchedData,
          category_icon: mapTypesToNumbers([
            { type: 1, icon: "" },
            { type: 2, icon: "" },
            { type: 3, icon: "" },
          ]),
        };
      }

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...mappedData,
        },
      }));

      if (mappedData.category_icon) {
        const newLandingPageImages = mappedData.category_icon.map((screen) => {
          const fullUrl = image_url + screen.icon;
          return fullUrl.endsWith(".svg") ? fullUrl : "";
        });
        setIconImages(newLandingPageImages);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      let payload = {
        modelName: "category_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getTenantDropdownData(data.tenant_id);
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }

      const responseData = await fetchData("getMasterList", payload);
      const dropdownResponseData = await dropdownData(
        responseData.data,
        "tenant_company_name"
      );
      if (id) {
        dropdownResponseData[0].options.forEach((element) => {
          if (element.value == id) {
            setSelectedTenant(element);
          }
        });
      }
      setTenantlist(dropdownResponseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedOption) => {
    setSelectedTenant(selectedOption);
    setTenantError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("checked", checked);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== "image/svg+xml") {
        toast.error("select only an SVG image.");
        e.target.value = "";
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const svgData = reader.result;
        const base64Data = window.btoa(svgData);
        const base64Svg = `data:image/svg;base64,${base64Data}`;

        setFormData((prevState) => {
          const newUploadImage = [...prevState.uploadImage];
          const categoryIconType =
            prevState.inputData.category_icon[index].type;

          let uploadImageIndex;
          if (categoryIconType == 1 || categoryIconType == "Small") {
            uploadImageIndex = 0;
          } else if (categoryIconType == 2 || categoryIconType == "Big") {
            uploadImageIndex = 1;
          } else if (categoryIconType == 3 || categoryIconType == "Other") {
            uploadImageIndex = 2;
          } else {
            console.error("Invalid category icon type");
            return prevState;
          }

          if (
            uploadImageIndex !== undefined &&
            newUploadImage[uploadImageIndex]
          ) {
            newUploadImage[uploadImageIndex].imagePath = base64Svg;
            setIsMediaRemoveAndDownload((prevState) => {
              const updatedLoadingState = [...prevState];
              updatedLoadingState[index] = true;
              return updatedLoadingState;
            });
            setIconImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = `data:image/svg+xml;base64,${base64Data}`;
              return newImages;
            });
          } else {
            console.error("Upload image index is out of bounds");
            return prevState;
          }

          return {
            ...prevState,
            uploadImage: newUploadImage,
          };
        });
      };
      reader.readAsText(file);
    }
  };

  const handleDownloadMediaClick = async (mediaFile, index) => {
    try {
      setMediaImageLoadingData((prevState) => {
        const updatedLoadingState = [...prevState];
        updatedLoadingState[index] = true;
        return updatedLoadingState;
      });

      const urlSegments = mediaFile.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];
      await downloadBlobData("", lastSegment);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setMediaImageLoadingData((prevState) => {
        const updatedLoadingState = [...prevState];
        updatedLoadingState[index] = true;
        return updatedLoadingState;
      });
    }
  };

  const handleRemoveMediaClick = (index) => {
    setIconImages((prevState) => {
      const updatedImages = [...prevState];
      updatedImages[index] = "";
      return updatedImages;
    });

    setFormData((prevState) => {
      const updatedFormData = { ...prevState };
      updatedFormData.uploadImage[index].imagePath = "";
      updatedFormData.inputData.category_icon[index].icon = "";
      return updatedFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    !selectedTenant ? setTenantError(true) : setTenantError(false);
    if (!formData.inputData.category_name || !formData.inputData.sequence) {
      return toast.warning("All fields are required!");
    }

    try {
      setLoadingData(true);

      const filteredUploadImage = formData.uploadImage.filter(
        (image) => image.imagePath !== ""
      );

      var payload = {
        modelName: "category_masters",
        inputData: formData.inputData,
        uploadImage: filteredUploadImage,
      };

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Academic Category saved successfully!");
        navigate("/academic-category-list");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/academic-category-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE ACADEMIC CATEGORY"
            breadcrumbItems={breadcrumbItems}
          />
          <Row className="mb-3s">
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">Tenant</Label>
                              <Select
                                value={selectedTenant}
                                onChange={handleSelectTenantChange}
                                options={tenantlist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {tenantError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "#ff4d4d",
                                    }}>
                                    Select Tenant
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Category Name <Asterisk />
                              </Label>
                              <AvField
                                name="category_name"
                                placeholder="Category name"
                                type="text"
                                errorMessage="Enter Category Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.category_name}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Sequence <Asterisk />
                              </Label>
                              <AvField
                                name="sequence"
                                placeholder="Sequence"
                                type="number"
                                errorMessage="Enter sequence"
                                className="form-control"
                                min="1"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleTextChange}
                                value={formData.inputData.sequence}
                              />
                            </div>
                          </Col>
                        </Row>

                        <h6
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            width: "160px",
                            backgroundColor: "grey",
                            color: "white",
                            paddingBlock: "5px",
                            paddingLeft: "5px",
                            marginBottom: "0px",
                            borderTopRightRadius: "15px",
                          }}>
                          Category Icon
                        </h6>
                        <Row>
                          <Col>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                {formData.inputData &&
                                  formData.inputData.category_icon && (
                                    <thead
                                      style={{ border: ".7px solid #bfbfbf" }}>
                                      <tr>
                                        <th>Sr. No</th>
                                        <th>Type</th>
                                        <th>Icon Upload (svg only)</th>
                                      </tr>
                                    </thead>
                                  )}
                                <tbody>
                                  {formData.inputData &&
                                    formData.inputData.category_icon &&
                                    formData.inputData.category_icon.map(
                                      (row, index) => (
                                        <tr
                                          key={row.key}
                                          style={{
                                            border: ".5px solid #bfbfbf",
                                          }}>
                                          <td>{index + 1}</td>
                                          <td>
                                            <AvField
                                              name={`type-${index}`}
                                              placeholder="type"
                                              type="text"
                                              errorMessage="Enter type"
                                              className="form-control"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                row.type == 1 ||
                                                row.type == "Small"
                                                  ? "Small"
                                                  : row.type == 2 ||
                                                    row.type == "Big"
                                                  ? "Big"
                                                  : row.type == 3 ||
                                                    row.type == "Other"
                                                  ? "Other"
                                                  : ""
                                              }
                                              readOnly
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="file"
                                              className="form-control"
                                              key={index}
                                              onChange={(e) =>
                                                handleFileChange(e, index)
                                              }
                                              accept="image/svg+xml"
                                            />
                                            {iconImages[index] && (
                                              <Col md="6" className="mt-1">
                                                <div className="mb-3 d-flex">
                                                  <img
                                                    src={iconImages[index]}
                                                    alt={`icon ${index + 1}`}
                                                    width="120px"
                                                    height="100px"
                                                    style={{
                                                      border: ".5px solid gray",
                                                      borderRadius: "5px",
                                                    }}
                                                  />
                                                  {!isMediaRemoveAndDownload[
                                                    index
                                                  ] && (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        marginLeft: "10px",
                                                      }}>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          gap: "15px",
                                                        }}>
                                                        {loadingMediaImageData[
                                                          index
                                                        ] ? (
                                                          <LoaderDownloadMedia />
                                                        ) : (
                                                          <img
                                                            src={downloadIcon}
                                                            alt="download"
                                                            width="30px"
                                                            height="30px"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              handleDownloadMediaClick(
                                                                iconImages[
                                                                  index
                                                                ],
                                                                index
                                                              )
                                                            }
                                                          />
                                                        )}
                                                        <img
                                                          src={removeIcon}
                                                          alt="remove"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleRemoveMediaClick(
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </Col>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.inputData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AcademicCategoryCreate;
