import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { academics, fetchData, user } from "../../../services/fetchData";

const breadcrumbItems = [];

const GuestUserList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    getUSerData();
    setTimeout(() => {
      getData();
    }, 1000);
  }, [searchKeyword]);

  // get listing data
  const getData = async () => {
    try {
      let tenantId = localStorage.getItem("tenantId");
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      authID = authID[0];
      const payload = {
        modelName: "temp_ibo_guest_user",
        search: {
          field_name: ["name"],
          searchKeyword: searchKeyword,
        },
        whereCondition: {
          is_active: 1,
        },
        pagination: {
          page: 1,
          pageSize: 1000,
        },
      };
      if (authID.type == 1 && tenantId) {
        payload.whereCondition.id = tenantId;
      }

      if (authID.type == 2) {
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
          const ibo_user_id = element.ibo_user_id;
          const user = userData.find((user) => user.id == ibo_user_id);
          element.ibo_user_name = user ? user.name : "-";
        });

        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUSerData = async () => {
    try {
      const payload = {
        modelName: "user_masters",
        whereCondition: {
          is_active: 1,
          type: 1,
        },
        pagination: {
          page: 1,
          pageSize: 1000,
        },
      };

      const responseData = await fetchData("getMasterList", payload, user);

      if (responseData.code == 1) {
        setUserData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // add button navigate function
  const handleClickAdd = () => {
    navigate("/guest-user-create");
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Name",
        accessor: (row) => (row.name && row.name ? row.name : "-"),
        disableFilters: true,
        filterable: false,
      },
      // {
      //   Header: "Email",
      //   accessor: (row) => (row.email && row.email ? row.email : "-"),
      //   disableFilters: true,
      //   filterable: false,
      // },
      // {
      //   Header: "User Name",
      //   accessor: (row) => (row.username && row.username ? row.username : "-"),
      //   disableFilters: true,
      //   filterable: false,
      // },
      {
        Header: "Connected IBO User",
        accessor: (row) =>
          row.ibo_user_name && row.ibo_user_name ? row.ibo_user_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Mobile Number",
        accessor: (row) =>
          row.mobile_number && row.mobile_number ? row.mobile_number : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
    ],
    []
  );

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Guest User List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Guest User
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onSearchInputData={getSearchInputData}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GuestUserList;
