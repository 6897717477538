import React, { useEffect, useMemo, useState, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, academics } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContentModuleList = () => {
  const breadcrumbItems = [];
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [fileData, setFileData] = useState({});

  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    getData();
  }, [searchKeyword]);

  const getData = async () => {
    try {
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      const payload = {
        type: 2,
        search: {
          field_name: ["title", "speaker_name"],
          searchKeyword: searchKeyword,
        },
      };
      const responseData = await fetchData(
        "modulelistbytype",
        payload,
        academics
      );

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      let sortedData = responseData.data.sort((a, b) => b.index - a.index);
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/manage-module-create");
  };

  const handleEditClick = (id) => {
    navigate(`/manage-module-create/${id}`);
  };

  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const validFileExtensions = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (file && !validFileExtensions.includes(file.type)) {
      toast.error("Wrong file type! Please choose an Excel file");
      fileInputRef.current.value = "";
    } else {
      setFileData(file);
      storeFileData(file);
    }
  };

  const storeFileData = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file?.name);

    const responseData = await fetchData(
      "academicbulkupload",
      formData,
      academics
    );
  };

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },
      {
        Header: "Module Title",
        accessor: (row) => (row.title && row.title ? row.title : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Speaker Name",
        accessor: (row) =>
          row.speaker_name && row.speaker_name ? row.speaker_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="primary"
            onClick={() => handleEditClick(row.original.id)}>
            <i className="fas fa-edit"></i>
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Manage Module List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Manage Module
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onSearchInputData={getSearchInputData}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContentModuleList;
