import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import {
  dropdownData,
  fetchData,
  image_url,
  academics,
  user,
  downloadBlobData,
} from "../../../services/fetchData";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { dropStyle } from "../../../Style/dropStyle";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const AcademicChannelsCreate = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  //toggling modals
  const [tagsIsHide, setTagsIsHide] = useState(false);
  const [postIsHide, setPostIsHide] = useState(true);
  const [categoriesIsHide, setCategoriesIsHide] = useState(true);
  const [moduleIsHide, setModuleIsHide] = useState(true);
  const [badgeIsHide, setbadgeIsHide] = useState(true);
  const [keywordIsHide, setKeywordIsHide] = useState(true);

  //selected Group Drop
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedGroupOption, setSelectedGroupOption] = useState([]);
  const [previousSelectedGroup, setPreviousSelectedGroup] = useState([]);

  //selected Audiance drop
  const [selectedAudiance, setSelectedAudiance] = useState([]);
  const [selectedAudianceOption, setSelectedAudianceOption] = useState([]);
  const [previousSelectedAudiance, setPreviousSelectedAudiance] = useState([]);

  // TagList
  const [tagSelection, setTagSelection] = useState([]);
  const [tagFilteredSelection, setTagFilteredSelection] = useState([]);
  const [tagSelected, setTagSelected] = useState([]);
  const [tagSearchTerm, setTagSearchTerm] = useState("");
  const [tagSelectedItem, setTagSelectedItem] = useState([]);
  const [tagRemoveSelectedItem, setRemoveTagSelectedItem] = useState([]);

  //PostList
  const [postSelection, setPostSelection] = useState([]);
  const [postFilteredSelection, setPostFilteredSelection] = useState([]);
  const [postSelected, setPostSelected] = useState([]);
  const [postSearchTerm, setPostSearchTerm] = useState("");
  const [postSelectedItem, setPostSelectedItem] = useState([]);
  const [postRemoveSelectedItem, setRemovePostSelectedItem] = useState([]);

  //CategoryList
  const [categorySelection, setCategorySelection] = useState([]);
  const [categoryFilteredSelection, setCategoryFilteredSelection] = useState(
    []
  );
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const [categorySelected, setCategorySelected] = useState([]);
  const [categorySelectedItem, setCategorySelectedItem] = useState([]);
  const [categoryRemoveSelectedItem, setRemoveCategorySelectedItem] = useState(
    []
  );

  //ModuleList
  const [moduleSelection, setModuleSelection] = useState([]);
  const [moduleFilteredSelection, setModuleFilteredSelection] = useState([]);
  const [moduleSelected, setModuleSelected] = useState([]);
  const [moduleSearchTerm, setModuleSearchTerm] = useState("");
  const [moduleSelectedItem, setModuleSelectedItem] = useState([]);
  const [moduleRemoveSelectedItem, setRemoveModuleSelectedItem] = useState([]);

  // BadgeList
  const [badgeSelection, setBadgeSelection] = useState([]);
  const [badgeFilteredSelection, setBadgeFilteredSelection] = useState([]);
  const [badgeSelected, setBadgeSelected] = useState([]);
  const [badgeSearchTerm, setBadgeSearchTerm] = useState("");
  const [badgeSelectedItem, setBadgeSelectedItem] = useState([]);
  const [badgeRemoveSelectedItem, setBadgeRemoveSelectedItem] = useState([]);

  // KeywordList
  const [keywordSelection, setKeywordSelection] = useState([]);
  const [keywordFilteredSelection, setKeywordFilteredSelection] = useState([]);
  const [keywordSelected, setKeywordSelected] = useState([]);
  const [keywordSearchTerm, setkeywordSearchTerm] = useState("");
  const [KeywordSelectedItem, setKeywordSelectedItem] = useState([]);
  const [KeywordRemoveSelectedItem, setKeywordRemoveSelectedItem] = useState(
    []
  );

  // media preview
  const [src, setSrc] = useState("");
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] =
    useState(false);

  //loading
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(false);

  const [fetchedData, setFetchedData] = useState(null);

  // storing formData data
  const [formData, setFormData] = useState({
    inputData: {
      type: 1,
      tenant_id: 1,
      channel_image: "",
      channel_title: "",
      summary: "",
      sequence: "",
      channel_keyword: [],
      setting: 1,
      subscription: "",
      speaker_name: "",
      enable_notification: 0,
      post_title: "",
      post_subtitle: "",
      speaker_stock_id: "",
      released_date: "",

      media: {
        title: "",
        type: "",
        url: "",
        content: "",
      },
      academic_channel_group_linkings: [],
      academic_channel_audience_linkings: [],
      academic_channel_tags: [],
      academic_channel_categories: [],
      academic_channel_users: [],
      academic_channel_post_associations: [],
      academic_channel_badges: [],

      is_having_module: 0,
      shareable: 0,
      is_active: 1,
    },

    uploadImage: {
      imagePath: "",
      folderPath: "",
      field_name: "channel_image",
    },
  });

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    getTagListData();
    getPostListData();
    getModuleListData();
    getCategoryListData();
    getBadgeListData();
    getKeywordListData();
  }, []);

  useEffect(() => {
    getSelectGroupDropData();
    getSelectAudianceDropData();
  }, []);

  useEffect(() => {
    setTagFilteredSelection(tagSelection);
    setModuleFilteredSelection(moduleSelection);
    setPostFilteredSelection(postSelection);
    setCategoryFilteredSelection(categorySelection);
    setBadgeFilteredSelection(badgeSelection);
    setKeywordFilteredSelection(keywordSelection);
  }, [
    tagSelection,
    moduleSelection,
    postSelection,
    categorySelection,
    badgeSelection,
    keywordSelection,
  ]);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.channel_image) {
        setSrc(image_url + `${data.channel_image}`);
      }

      const flattenedGroupOptions = selectedGroupOption.flatMap(
        (option) => option.options
      );
      const initialGroups = data.academic_channel_group_linkings.map(
        (group) => {
          let groupOption = flattenedGroupOptions.find(
            (option) => option.value == group.group_id
          );
          return {
            id: group.id,
            channel_id: group.channel_id,
            value: group.group_id,
            label: groupOption ? groupOption.label : group.group_id,
            is_active: 1,
          };
        }
      );

      setSelectedGroup(initialGroups);
      setPreviousSelectedGroup(initialGroups);

      const flattenedAudienceOptions = selectedAudianceOption.flatMap(
        (option) => option.options
      );
      const initialAudiences = data.academic_channel_audience_linkings.map(
        (audience) => {
          let audienceOption = flattenedAudienceOptions.find(
            (option) => option.value == audience.audience_id
          );

          return {
            id: audience.id,
            channel_id: audience.channel_id,
            value: audience.audience_id,
            label: audienceOption ? audienceOption.label : audience.audience_id,
            is_active: 1,
          };
        }
      );
      setSelectedAudiance(initialAudiences);
      setPreviousSelectedAudiance(initialAudiences);

      let accessTagId = data.academic_channel_tags.map((item) => item.tag_id);
      let newfilterTagData = tagFilteredSelection.filter((item) =>
        accessTagId.includes(item.id)
      );

      setTagSelected(
        newfilterTagData.map((tag) => ({
          id: tag.id,
          tag_name: tag.tag_name,
          is_active: tag.is_active,
        }))
      );

      let accessCategoryId = data.academic_channel_categories.map(
        (item) => item.category_id
      );
      let newfilterCategoryData = categoryFilteredSelection.filter((item) =>
        accessCategoryId.includes(item.id)
      );
      setCategorySelected(
        newfilterCategoryData.map((category) => ({
          category_name: category.category_name,
          id: category.id,
          is_active: category.is_active,
        }))
      );

      let accessPostId = data.academic_channel_post_associations.map(
        (item) => item.post_id
      );

      let newfilterPostData = postFilteredSelection.filter((item) =>
        accessPostId.includes(item.id)
      );

      setPostSelected(
        newfilterPostData.map((post) => ({
          title: post.title,
          id: post.id,
          is_active: post.is_active,
        }))
      );

      let accessModuleId = data.academic_channel_post_associations.map(
        (item) => item.module_id
      );
      let newfilterModuleData = moduleFilteredSelection.filter((item) =>
        accessModuleId.includes(item.id)
      );

      setModuleSelected(
        newfilterModuleData.map((module) => ({
          title: module.title,
          id: module.id,
          is_active: module.is_active,
        }))
      );

      let accessBadgeId = data.academic_channel_badges
        .filter((item) => item.reference == 1)
        .map((item) => item.badge_id);
      // let accessBadgeId = data.academic_channel_badges.map((item) => item.badge_id);
      let newfilterBadgeData = badgeFilteredSelection.filter((item) =>
        accessBadgeId.includes(item.id)
      );

      setBadgeSelected(
        newfilterBadgeData.map((badge) => ({
          id: badge.id,
          name: badge.name,
          is_active: badge.is_active,
        }))
      );

      if (
        data.channel_keyword &&
        Array.isArray(data.channel_keyword) &&
        data.channel_keyword.length > 0
      ) {
        let accessKeywordId =
          data.channel_keyword &&
          Array.isArray(data.channel_keyword) &&
          data.channel_keyword.length > 0
            ? data.channel_keyword.map((item) => item.keyword_id)
            : [];
        let newfilterKeywordData = keywordFilteredSelection.filter((item) => {
          return accessKeywordId.includes(item.id);
        });

        setKeywordSelected(
          newfilterKeywordData.map((keyword) => ({
            id: keyword.id,
            keyword_name: keyword.keyword_name,
          }))
        );
      }
    }
  }, [
    tagFilteredSelection,
    postFilteredSelection,
    categoryFilteredSelection,
    moduleFilteredSelection,
    badgeFilteredSelection,
    keywordFilteredSelection,
    fetchedData,
    selectedGroupOption,
    selectedAudianceOption,
  ]);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "academic_channels",
        relations: [
          {
            module: "academic_channel_group_linkings",
            moduleas: "academic_channel_group_linkings",
            subModule: "temp_group_id_masters",
            subModuleas: "temp_group_id_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_audience_linkings",
            moduleas: "academic_channel_audience_linkings",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_tags",
            moduleas: "academic_channel_tags",
            subModule: "tag_masters",
            subModuleas: "tag_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_categories",
            moduleas: "academic_channel_categories",
            subModule: "temp_category_masters",
            subModuleas: "temp_category_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_users",
            moduleas: "academic_channel_users",
            subModule: "temp_user_datas",
            subModuleas: "temp_user_datas",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_post_associations",
            moduleas: "academic_channel_post_associations",
            subModule: "academic_channel_posts",
            subModuleas: "academic_channel_posts",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_badges",
            moduleas: "academic_channel_badges",
            subModule: "badge_masters",
            subModuleas: "badge_masters",
            isSubWhereConditionRequired: true,
          },
        ],

        subWhereCondition: {
          is_active: 1,
        },
      };

      if (id) {
        payload.whereCondition = { id: id, ...payload.whereCondition };
      }
      let responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];

        if (id) {
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  // get  list data  ( Tag/Modal/Post/Category/User/Badge/Keyword )
  const getTagListData = async () => {
    try {
      const payload = {
        modelName: "tag_masters",
        whereCondition: { is_active: 1 },
      };
      const responseData = await fetchData("getMasterList", payload, academics);
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);

        setTagSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPostListData = async () => {
    try {
      let payload = {
        type: 1,
      };

      const responseData = await fetchData(
        "postlistbytype",
        payload,
        academics
      );

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setPostSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getModuleListData = async () => {
    try {
      let payload = {
        modelName: "academic_channel_modules",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setModuleSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCategoryListData = async () => {
    try {
      const payload = {
        modelName: "category_masters",
        relations: [{ module: "tenant_masters", moduleas: "tenant_masters" }],
        whereCondition: { type: 1, is_deleted: 0 },
      };
      const responseData = await fetchData("getMasterList", payload);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);

      setCategorySelection(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getBadgeListData = async () => {
    try {
      const payload = {
        modelName: "badge_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);

      setBadgeSelection(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getKeywordListData = async () => {
    try {
      const payload = {
        modelName: "default_keywords",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);
      setKeywordSelection(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get Select Drop data ( Group/audiance )
  const getSelectGroupDropData = async () => {
    try {
      let payload = {
        modelName: "temp_group_id_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_group_id"
        );

        setSelectedGroupOption(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSelectAudianceDropData = async () => {
    try {
      let payload = {
        modelName: "temp_audience_id_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_audience_id"
        );
        setSelectedAudianceOption(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //  onchange Group / Audiance
  const handleSelectGroupChange = (selectedGroupOption) => {
    const currentGroupIds = selectedGroup.map((group) => group.value);
    const newGroupIds = selectedGroupOption.map((group) => group.value);

    const removedGroups = previousSelectedGroup
      .filter((group) => !newGroupIds.includes(group.value))
      .map((group) => ({ ...group, is_active: 0 }));
    const addedGroups = selectedGroupOption
      .filter((group) => !currentGroupIds.includes(group.value))
      .map((group) => ({
        value: group.value,
        label: group.label,
        is_active: 1,
      }));

    const updatedGroups = [
      ...previousSelectedGroup.filter(
        (group) => newGroupIds.includes(group.value) && group.is_active === 1
      ),
      ...addedGroups,
      ...removedGroups,
    ];
    setSelectedGroup(selectedGroupOption);
    setPreviousSelectedGroup(updatedGroups);

    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_group_linkings: updatedGroups.map((group) => ({
          id: group.id,
          channel_id: group.channel_id,
          group_id: group.value,
          is_active: group.is_active,
        })),
      },
    }));
  };

  const handleSelectAudienceChange = (selectedAudianceOption) => {
    const currentAudienceIds = selectedAudiance.map(
      (audience) => audience.value
    );
    const newAudienceIds = selectedAudianceOption.map(
      (audience) => audience.value
    );

    const removedAudiences = previousSelectedAudiance
      .filter((audience) => !newAudienceIds.includes(audience.value))
      .map((audience) => ({ ...audience, is_active: 0 }));
    const addedAudiences = selectedAudianceOption
      .filter((audience) => !currentAudienceIds.includes(audience.value))
      .map((audience) => ({
        value: audience.value,
        label: audience.label,
        is_active: 1,
      }));

    const updatedAudiences = [
      ...previousSelectedAudiance.filter(
        (audience) =>
          newAudienceIds.includes(audience.value) && audience.is_active === 1
      ),
      ...addedAudiences,
      ...removedAudiences,
    ];
    setSelectedAudiance(selectedAudianceOption);
    setPreviousSelectedAudiance(updatedAudiences);

    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_audience_linkings: updatedAudiences.map(
          (audience) => ({
            id: audience.id,
            channel_id: audience.channel_id,
            audience_id: audience.value,
            is_active: audience.is_active,
          })
        ),
      },
    }));
  };

  //  handle Search  (Tags/Modal/Post/Category/User/Badge/keyword)
  const handleTagSearch = (e) => {
    const { value } = e.target;
    setTagSearchTerm(value);
    if (value === "") {
      setTagFilteredSelection(tagSelection);
    } else {
      setTagFilteredSelection(
        tagSelection.filter((item) =>
          item.tag_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleModuleSearch = (e) => {
    const { value } = e.target;
    setModuleSearchTerm(value);
    if (value === "") {
      setModuleFilteredSelection(moduleSelection);
    } else {
      setModuleFilteredSelection(
        moduleSelection.filter((item) =>
          item.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handlePostSearch = (e) => {
    const { value } = e.target;
    setPostSearchTerm(value);
    if (value === "") {
      setPostFilteredSelection(postSelection);
    } else {
      setPostFilteredSelection(
        postSelection.filter((item) =>
          item.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleCategorySearch = (e) => {
    const { value } = e.target;
    setCategorySearchTerm(value);
    if (value === "") {
      setCategoryFilteredSelection(categorySelection);
    } else {
      setCategoryFilteredSelection(
        categorySelection.filter((item) =>
          item.category_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleBadgesSearch = (e) => {
    const { value } = e.target;
    setBadgeSearchTerm(value);
    if (value === "") {
      setBadgeFilteredSelection(badgeSelection);
    } else {
      setBadgeFilteredSelection(
        badgeSelection.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleKeywordsSearch = (e) => {
    const { value } = e.target;
    setkeywordSearchTerm(value);
    if (value === "") {
      setKeywordFilteredSelection(keywordSelection);
    } else {
      setKeywordFilteredSelection(
        keywordSelection.filter((item) =>
          item.keyword_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  // handel select  (Tags/Modal/Post/Category/User/Badge/keyword)

  const handleTagSelect = () => {
    const newSelectedTags = tagSelected.concat(tagSelectedItem);
    const newAcademicTags = tagSelectedItem.map((item) => ({
      ...(id ? { id: null } : {}),
      tag_id: item.id,
      is_active: 1,
    }));

    setTagSelected(newSelectedTags);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_tags: [
          ...prevState.inputData.academic_channel_tags,
          ...newAcademicTags,
        ],
      },
    }));
    setTagSelectedItem([]);
  };

  const handleModuleSelect = () => {
    const newSelectedModule = moduleSelected.concat(moduleSelectedItem);
    const newAcademicModule = moduleSelectedItem.map((item) => ({
      ...(id ? { id: null } : {}),
      module_id: item.id,
      post_id: null,
      is_active: 1,
    }));

    setModuleSelected(newSelectedModule);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_post_associations: [
          ...prevState.inputData.academic_channel_post_associations,
          ...newAcademicModule,
        ],
      },
    }));
    setModuleSelectedItem([]);
  };

  const handlePostSelect = () => {
    const newSelectedPost = postSelected.concat(postSelectedItem);
    const newAcademicPost = postSelectedItem.map((item) => ({
      ...(id ? { id: null } : {}),
      post_id: item.id,
      module_id: null,
      is_active: 1,
    }));

    setPostSelected(newSelectedPost);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_post_associations: [
          ...prevState.inputData.academic_channel_post_associations,
          ...newAcademicPost,
        ],
      },
    }));
    setPostSelectedItem([]);
  };

  const handleCategorySelect = () => {
    const newSelectedCategory = categorySelected.concat(categorySelectedItem);
    const newAcademicCategory = categorySelectedItem.map((item) => ({
      ...(id ? { id: null } : {}),
      category_id: item.id,
      is_active: 1,
    }));

    setCategorySelected(newSelectedCategory);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_categories: [
          ...prevState.inputData.academic_channel_categories,
          ...newAcademicCategory,
        ],
      },
    }));
    setCategorySelectedItem([]);
  };

  const handleBadgeSelect = () => {
    const newSelectedBadge = badgeSelected.concat(badgeSelectedItem);
    const newAcademicBadge = badgeSelectedItem.map((item) => ({
      ...(id ? { id: null } : {}),
      reference: 1,
      badge_id: item.id,
      is_active: 1,
    }));

    setBadgeSelected(newSelectedBadge);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_badges: [
          ...prevState.inputData.academic_channel_badges,
          ...newAcademicBadge,
        ],
      },
    }));
    setBadgeSelectedItem([]);
  };

  const handleKeywordSelect = () => {
    const newSelectedKeyword = keywordSelected.concat(KeywordSelectedItem);
    const newAcademicKeyword = KeywordSelectedItem.map((item) => ({
      keyword_id: item.id,
      keyword_name: item.keyword_name,
    }));

    setKeywordSelected(newSelectedKeyword);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        channel_keyword: [
          ...prevState.inputData.channel_keyword,
          ...newAcademicKeyword,
        ],
      },
    }));
    setKeywordSelectedItem([]);
  };

  //handle Rememove item data   (Tags/Modal/Post/Category/User/Badge/keyword)

  const handleTagRemove = () => {
    if (tagRemoveSelectedItem.length > 0) {
      const removeTagIds = tagRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_tags: prevState.inputData.academic_channel_tags.map(
            (tag) => {
              if (removeTagIds.includes(tag.tag_id)) {
                return { ...tag, is_active: 0 };
              }
              return tag;
            }
          ),
        },
      }));

      // updatte the tagSelected state to reflect the remove on ui
      setTagSelected((prevSelected) =>
        prevSelected.filter((tag) => !removeTagIds.includes(tag.id))
      );

      setRemoveTagSelectedItem([]);
    } else {
      toast.error("No tags selected to remove");
    }
  };

  const handleModuleRemove = () => {
    if (moduleRemoveSelectedItem.length > 0) {
      const removeModuleIds = moduleRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_post_associations:
            prevState.inputData.academic_channel_post_associations.map(
              (module) => {
                if (removeModuleIds.includes(module.module_id)) {
                  return { ...module, is_active: 0 };
                }
                return module;
              }
            ),
        },
      }));
      setModuleSelected((prevSelected) =>
        prevSelected.filter((module) => !removeModuleIds.includes(module.id))
      );

      setRemoveModuleSelectedItem([]);
    } else {
      toast.error("No module selected to remove");
    }
  };

  const handlePostRemove = () => {
    if (postRemoveSelectedItem.length > 0) {
      const removePostIds = postRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_post_associations:
            prevState.inputData.academic_channel_post_associations.map(
              (post) => {
                if (removePostIds.includes(post.post_id)) {
                  return { ...post, is_active: 0 };
                }
                return post;
              }
            ),
        },
      }));

      setPostSelected((prevSelected) =>
        prevSelected.filter((post) => !removePostIds.includes(post.id))
      );
      setRemovePostSelectedItem([]);
    } else {
      toast.error("No post selected to remove");
    }
  };

  const handleCategoryRemove = () => {
    if (categoryRemoveSelectedItem.length > 0) {
      const removecategoryIds = categoryRemoveSelectedItem.map(
        (item) => item.id
      );

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_categories:
            prevState.inputData.academic_channel_categories.map((category) => {
              if (removecategoryIds.includes(category.category_id)) {
                return { ...category, is_active: 0 };
              }
              return category;
            }),
        },
      }));

      setCategorySelected((prevSelected) =>
        prevSelected.filter(
          (category) => !removecategoryIds.includes(category.id)
        )
      );
      setRemoveCategorySelectedItem([]);
    } else {
      toast.error("No category selected to remove");
    }
  };

  const handleBadgeRemove = () => {
    if (badgeRemoveSelectedItem.length > 0) {
      const removeBadgeIds = badgeRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_badges:
            prevState.inputData.academic_channel_badges.map((badge) => {
              if (removeBadgeIds.includes(badge.badge_id)) {
                return { ...badge, is_active: 0 };
              }
              return badge;
            }),
        },
      }));

      setBadgeSelected((prevSelected) =>
        prevSelected.filter((badge) => !removeBadgeIds.includes(badge.id))
      );

      setBadgeRemoveSelectedItem([]);
    } else {
      toast.error("No badge selected to remove");
    }
  };

  const handleKeywordRemove = () => {
    if (KeywordRemoveSelectedItem.length > 0) {
      const removeKeywordIds = KeywordRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          channel_keyword: prevState.inputData.channel_keyword.filter(
            (keyword) => !removeKeywordIds.includes(keyword.keyword_id)
          ),
        },
      }));

      setKeywordSelected((prevSelected) =>
        prevSelected.filter((keyword) => !removeKeywordIds.includes(keyword.id))
      );
      setKeywordRemoveSelectedItem([]);
    } else {
      toast.error("No keyword selected to remove");
    }
  };

  // handle Item Click  (Tags/Modal/Post/Category/User/Badge/keyword)

  const handleTagItemClick = (item) => {
    setTagSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((tag) => tag !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleModuleItemClick = (item) => {
    setModuleSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((post) => post !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handlePostItemClick = (item) => {
    setPostSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((post) => post !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleCategoryItemClick = (item) => {
    setCategorySelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((category) => category !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleBadgeItemClick = (item) => {
    setBadgeSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((badge) => badge !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleKeywordItemClick = (item) => {
    setKeywordSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((keyword) => keyword !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  // handel Remove item data click  (Tags/Modal/Post/Category/User/Badge/keyword)
  const handleRemoveTagItemClick = (item) => {
    setRemoveTagSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((tag) => tag !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemoveModuleItemClick = (item) => {
    setRemoveModuleSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((tag) => tag !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemovePostItemClick = (item) => {
    setRemovePostSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((post) => post !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemoveCategoryItemClick = (item) => {
    setRemoveCategorySelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((category) => category !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemoveBadgeItemClick = (item) => {
    setBadgeRemoveSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((badge) => badge !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemoveKeywordItemClick = (item) => {
    setKeywordRemoveSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((keyword) => keyword !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  // handle Text Change
  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked === true ? 1 : 0) : value,
      },
    }));
  };

  // handle File Change ( uplaod Images )
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setSrc(reader.result);
        setIsMediaRemoveAndDownload(true);
      };
      reader.readAsDataURL(file);
    }
  };

  // handle media download
  const handleDownloadMediaClick = async (mediaFile) => {
    try {
      setMediaImageLoadingData(true);
      const urlSegments = mediaFile.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];
      await downloadBlobData(academics, lastSegment);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setMediaImageLoadingData(false);
    }
  };

  // handle media remove
  const handleRemoveMediaClick = () => {
    setSrc("");
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        channel_image: "",
      },
    }));
  };

  // For changing tooltip display prop
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  // //Toggling(open/close)  (Tags/Modal/Post/Category/User/Badge/keyword)
  const handelTags = () => {
    setTagsIsHide(!tagsIsHide);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    setModuleIsHide(true);
    setbadgeIsHide(true);
    setKeywordIsHide(true);
    if (!tagsIsHide) {
      scrollToSection("tagSection");
    }
  };

  const handelPost = () => {
    setPostIsHide(!postIsHide);
    setTagsIsHide(true);
    setCategoriesIsHide(true);
    setModuleIsHide(true);
    setbadgeIsHide(true);
    setKeywordIsHide(true);
    if (postIsHide) {
      scrollToSection("postSection");
    }
  };

  const handelCategories = () => {
    setCategoriesIsHide(!categoriesIsHide);
    setTagsIsHide(true);
    setPostIsHide(true);
    setModuleIsHide(true);
    setbadgeIsHide(true);
    setKeywordIsHide(true);
    if (categoriesIsHide) {
      scrollToSection("categoriesSection");
    }
  };

  const handelModule = () => {
    setModuleIsHide(!moduleIsHide);
    setTagsIsHide(true);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    setbadgeIsHide(true);
    setKeywordIsHide(true);
    if (moduleIsHide) {
      scrollToSection("moduleSection");
    }
  };

  const handelBadges = () => {
    setbadgeIsHide(!badgeIsHide);
    setTagsIsHide(true);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    setModuleIsHide(true);
    setKeywordIsHide(true);
    if (badgeIsHide) {
      scrollToSection("badgeSection");
    }
  };

  const handelKeyword = () => {
    setKeywordIsHide(!keywordIsHide);
    setbadgeIsHide(true);
    setTagsIsHide(true);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    setModuleIsHide(true);
    if (keywordIsHide) {
      scrollToSection("keywordSection");
    }
  };

  //handle form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.inputData.channel_title) {
      return toast.warning("All fields are required");
    }
    try {
      setLoadingData(true);
      var payload = {
        modelName: "academic_channels",
        relation: [
          {
            modelName: "academic_channel_group_linkings",
          },
          {
            modelName: "academic_channel_audience_linkings",
          },
          {
            modelName: "academic_channel_tags",
          },
          {
            modelName: "academic_channel_categories",
          },
          {
            modelName: "academic_channel_post_associations",
          },
          {
            modelName: "academic_channel_users",
          },

          {
            modelName: "academic_channel_badges",
          },
        ],
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (id) {
        payload.id = id;
      }

      const responseData = id
        ? await fetchData("updaterelationaldata", payload, academics)
        : await fetchData("createAndUpdateMaster", payload, academics);

      if (responseData.code == 1) {
        toast.success("Manage Channel saved successfully!");
        navigate("/academic-channels-list");
      } else {
        toast.error("Error while saving Manage Channel!");
        navigate("/academic-channels-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  console.log("formData=>", formData);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE ACADEMIC CHANNEL"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      className="needs-validation"
                      onSubmit={handleSubmit}>
                      <>
                        <Row className="mt-1">
                          {/* <div className="m-3 ">
                        <h5
                          style={{
                            textAlign: "center",
                            fontFamily: "revert",
                            color: "#a6a6a6",
                            fontSize: "22px",
                          }}>
                          Channel Settings
                        </h5>
                      </div> */}

                          <Row>
                            <Row className="mt-5">
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Select Group
                                  </Label>
                                  <Select
                                    isMulti={true}
                                    value={selectedGroup}
                                    onChange={handleSelectGroupChange}
                                    options={selectedGroupOption}
                                    classNamePrefix="select1-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                    styles={dropStyle}
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Select Audiance
                                  </Label>
                                  <Select
                                    isMulti={true}
                                    value={selectedAudiance}
                                    onChange={handleSelectAudienceChange}
                                    options={selectedAudianceOption}
                                    classNamePrefix="select2-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                    styles={dropStyle}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-1">
                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Channel title
                                    <Asterisk />
                                  </Label>
                                  <AvField
                                    name="channel_title"
                                    placeholder="Channel title"
                                    type="text"
                                    errorMessage="Enter channel title Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={formData.inputData.channel_title}
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Speaker Group Name
                                  </Label>
                                  <AvField
                                    name="speaker_name"
                                    placeholder="Speaker Group Name"
                                    type="text"
                                    errorMessage="Enter Speaker Group Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    value={formData.inputData.speaker_name}
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Released Date
                                  </Label>
                                  <AvField
                                    name="released_date"
                                    placeholder="Released Date"
                                    type="date"
                                    errorMessage="Enter Released Date Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    value={formData.inputData.released_date}
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Speaker Stock id
                                  </Label>
                                  <AvField
                                    name="speaker_stock_id"
                                    placeholder="Speaker Stock id"
                                    type="text"
                                    errorMessage="Enter Speaker Stock id Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    value={formData.inputData.speaker_stock_id}
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <Label
                                  className="form-label"
                                  htmlFor="validationCustom01">
                                  Image
                                </Label>
                                <div className="input-group">
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="customFile"
                                    onChange={handleFileChange}
                                  />
                                </div>
                                {src && (
                                  <Col md="6" className="mt-1">
                                    <div className="mb-3 d-flex">
                                      <div>
                                        <img
                                          src={src}
                                          alt="channel image"
                                          width="120px"
                                          height="100px"
                                          style={{
                                            border: ".5px solid gray",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      </div>
                                      {!isMediaRemoveAndDownload && (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginLeft: "10px",
                                          }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "15px",
                                            }}>
                                            {loadingMediaImageData ? (
                                              <LoaderDownloadMedia />
                                            ) : (
                                              <img
                                                src={downloadIcon}
                                                alt="download"
                                                width="30px"
                                                height="30px"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleDownloadMediaClick(src)
                                                }
                                              />
                                            )}
                                            <img
                                              src={removeIcon}
                                              alt="remove"
                                              width="30px"
                                              height="30px"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={handleRemoveMediaClick}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                )}
                              </Col>

                              <Col md="6">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="validationCustom01">
                                    Summary
                                  </Label>
                                  <AvField
                                    name="summary"
                                    placeholder="Summary "
                                    type="textarea"
                                    errorMessage="Enter Summary"
                                    rows="3"
                                    onChange={handleTextChange}
                                    value={formData.inputData.summary}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">Sequence</Label>
                                  <AvField
                                    name="sequence"
                                    placeholder="Sequence"
                                    type="text"
                                    errorMessage="Enter Sequence Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    value={formData.inputData.sequence}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Row>

                          <Row className="mt-3">
                            <Col
                              style={{
                                textAlign: "center",
                              }}>
                              <div className="form-check mt-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="isHavingModule"
                                  required=""
                                  name="is_having_module"
                                  value={formData.inputData.is_having_module}
                                  checked={formData.inputData.is_having_module}
                                  onChange={handleTextChange}
                                  style={{ width: 18, height: 18 }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="enable_notification"
                                  style={{ fontSize: 16, marginLeft: 10 }}>
                                  Is Having Module
                                </label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-5">
                            <div className="m-3">
                              <h5
                                id="tagSection"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  fontSize: 18,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: !tagsIsHide ? "black" : "gray",
                                }}
                                onClick={handelTags}>
                                Tags
                                <i
                                  className={`mdi mdi-chevron-${
                                    tagsIsHide ? "down" : "up"
                                  } mt-1`}
                                  style={{ fontSize: "22px" }}
                                />
                              </h5>
                            </div>
                            {!tagsIsHide && (
                              <Row
                                // id="tagSection"
                                style={{
                                  marginLeft: "1px",
                                  padding: "10px",
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 2px 1px gray",
                                }}>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    marginTop: "17px",
                                  }}>
                                  <Col
                                    md="5"
                                    style={{
                                      display: "flex",
                                      margin: 0,
                                      padding: 0,
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        margin: 0,
                                        padding: 0,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                        gap: "5px",
                                      }}>
                                      <AvField
                                        name="tagsearch"
                                        placeholder="Search Tag"
                                        type="search"
                                        // errorMessage="Enter Type of Search"
                                        className="form-control m-0"
                                        id="validationCustom01"
                                        onChange={handleTagSearch}
                                        value={tagSearchTerm}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                  }}>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label "
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selection: {tagFilteredSelection.length}
                                      </Label>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {tagFilteredSelection.map(
                                          (item, index) => {
                                            const isTagAlreadySelected =
                                              tagSelected.some(
                                                (tag) => tag.id === item.id
                                              );
                                            const isItemSelected =
                                              tagSelectedItem.some(
                                                (tag) => tag.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  cursor: isTagAlreadySelected
                                                    ? "not-allowed"
                                                    : "pointer",
                                                  padding: "5px",
                                                  backgroundColor:
                                                    isItemSelected
                                                      ? "lightblue"
                                                      : "",
                                                  color: isTagAlreadySelected
                                                    ? "gray"
                                                    : "black",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  !isTagAlreadySelected &&
                                                  handleTagItemClick(item)
                                                }>
                                                {item.tag_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col
                                    md="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-right"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleTagSelect}
                                        />
                                      </div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-left"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleTagRemove}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selected: {tagSelected.length}
                                      </Label>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {tagSelected.map((item, index) => {
                                          const isItemSelectedForRemoval =
                                            tagRemoveSelectedItem.some(
                                              (tag) => tag.id === item.id
                                            );
                                          return (
                                            <li
                                              key={index}
                                              style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor:
                                                  isItemSelectedForRemoval
                                                    ? "lightblue"
                                                    : "",
                                                marginBlock: "2px",
                                              }}
                                              onClick={() =>
                                                handleRemoveTagItemClick(item)
                                              }>
                                              {item.tag_name}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>
                            )}
                          </Row>
                          {formData.inputData.is_having_module ? (
                            <Row className="mt-5">
                              <div className="m-3">
                                <h5
                                  id="moduleSection"
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    fontSize: 18,
                                    color: !moduleIsHide ? "black" : "gray",
                                  }}
                                  onClick={handelModule}>
                                  Module{" "}
                                  <i
                                    className={`mdi mdi-chevron-${
                                      moduleIsHide ? "down" : "up"
                                    } mt-1`}
                                    style={{ fontSize: "22px" }}
                                  />
                                </h5>
                              </div>
                              {!moduleIsHide && (
                                <Row
                                  style={{
                                    marginLeft: "1px",
                                    padding: "10px",
                                    border: "1px solid gray",
                                    borderRadius: "5px",
                                    boxShadow: "0px 0px 2px 1px gray",
                                  }}>
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: 0,
                                      padding: 0,
                                      marginTop: "17px",
                                    }}>
                                    <Col
                                      md="5"
                                      style={{
                                        display: "flex",
                                        margin: 0,
                                        padding: 0,
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}>
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          margin: 0,
                                          padding: 0,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          alignContent: "center",
                                          gap: "5px",
                                        }}>
                                        <AvField
                                          name="modulesearch"
                                          placeholder="Search Module"
                                          type="search"
                                          // errorMessage="Enter Type of Search"
                                          className="form-control m-0"
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                          id="validationCustom02"
                                          onChange={handleModuleSearch}
                                          value={moduleSearchTerm}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: 0,
                                      padding: 0,
                                    }}>
                                    <Col md="4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label "
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          Selection:{" "}
                                          {moduleFilteredSelection.length}
                                        </Label>
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: 0,
                                            border: "1px solid black",
                                            height: "200px",
                                            overflow: "auto",
                                          }}>
                                          {moduleFilteredSelection.map(
                                            (item, index) => {
                                              const isModuleAlreadySelected =
                                                moduleSelected.some(
                                                  (module) =>
                                                    module.id === item.id
                                                );
                                              const isItemSelected =
                                                moduleSelectedItem.some(
                                                  (module) =>
                                                    module.id === item.id
                                                );
                                              return (
                                                <li
                                                  key={index}
                                                  style={{
                                                    cursor:
                                                      isModuleAlreadySelected
                                                        ? "not-allowed"
                                                        : "pointer",
                                                    padding: "5px",
                                                    backgroundColor:
                                                      isItemSelected
                                                        ? "lightblue"
                                                        : "",
                                                    color:
                                                      isModuleAlreadySelected
                                                        ? "gray"
                                                        : "black",
                                                    marginBlock: "2px",
                                                  }}
                                                  onClick={() =>
                                                    !isModuleAlreadySelected &&
                                                    handleModuleItemClick(item)
                                                  }>
                                                  {item.title}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </Col>
                                    <Col
                                      md="1"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}>
                                      <div>
                                        <div>
                                          <i
                                            className="fas fa-long-arrow-alt-right"
                                            style={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handleModuleSelect}
                                          />
                                        </div>
                                        <div>
                                          <i
                                            className="fas fa-long-arrow-alt-left"
                                            style={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handleModuleRemove}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          Selected: {moduleSelected.length}
                                        </Label>
                                        <ul
                                          // id="postSection"
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: 0,
                                            border: "1px solid black",
                                            height: "200px",
                                            overflow: "auto",
                                          }}>
                                          {moduleSelected.map((item, index) => {
                                            const isItemSelectedForRemoval =
                                              moduleRemoveSelectedItem.some(
                                                (module) =>
                                                  module.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  padding: "5px",
                                                  cursor: "pointer",
                                                  backgroundColor:
                                                    isItemSelectedForRemoval
                                                      ? "lightblue"
                                                      : "",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  handleRemoveModuleItemClick(
                                                    item
                                                  )
                                                }>
                                                {item.title}
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              )}
                            </Row>
                          ) : (
                            <Row className="mt-5">
                              <div className="m-3">
                                <h5
                                  id="postSection"
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    fontSize: 18,
                                    color: !postIsHide ? "black" : "gray",
                                  }}
                                  onClick={handelPost}>
                                  Posts{" "}
                                  <i
                                    className={`mdi mdi-chevron-${
                                      postIsHide ? "down" : "up"
                                    } mt-1`}
                                    style={{ fontSize: "22px" }}
                                  />
                                </h5>
                              </div>
                              {!postIsHide && (
                                <Row
                                  // id="postSection"
                                  style={{
                                    marginLeft: "1px",
                                    padding: "10px",
                                    border: "1px solid gray",
                                    borderRadius: "5px",
                                    boxShadow: "0px 0px 2px 1px gray",
                                  }}>
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: 0,
                                      padding: 0,
                                      marginTop: "17px",
                                    }}>
                                    <Col
                                      md="5"
                                      style={{
                                        display: "flex",
                                        margin: 0,
                                        padding: 0,
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}>
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          margin: 0,
                                          padding: 0,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          alignContent: "center",
                                          gap: "5px",
                                        }}>
                                        <AvField
                                          name="postsearch"
                                          placeholder="Search Post"
                                          type="search"
                                          // errorMessage="Enter Type of Search"
                                          className="form-control m-0"
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                          id="validationCustom03"
                                          onChange={handlePostSearch}
                                          value={postSearchTerm}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: 0,
                                      padding: 0,
                                    }}>
                                    <Col md="4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label "
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          Selection:{" "}
                                          {postFilteredSelection.length}
                                        </Label>
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: 0,
                                            border: "1px solid black",
                                            height: "200px",
                                            overflow: "auto",
                                          }}>
                                          {postFilteredSelection.map(
                                            (item, index) => {
                                              const isPostAlreadySelected =
                                                postSelected.some(
                                                  (post) => post.id === item.id
                                                );
                                              const isItemSelected =
                                                postSelectedItem.some(
                                                  (post) => post.id === item.id
                                                );
                                              return (
                                                <li
                                                  key={index}
                                                  style={{
                                                    cursor:
                                                      isPostAlreadySelected
                                                        ? "not-allowed"
                                                        : "pointer",
                                                    padding: "5px",
                                                    backgroundColor:
                                                      isItemSelected
                                                        ? "lightblue"
                                                        : "",
                                                    color: isPostAlreadySelected
                                                      ? "gray"
                                                      : "black",
                                                    marginBlock: "2px",
                                                  }}
                                                  onClick={() =>
                                                    !isPostAlreadySelected &&
                                                    handlePostItemClick(item)
                                                  }>
                                                  {item.title}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </Col>
                                    <Col
                                      md="1"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}>
                                      <div>
                                        <div>
                                          <i
                                            className="fas fa-long-arrow-alt-right"
                                            style={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handlePostSelect}
                                          />
                                        </div>
                                        <div>
                                          <i
                                            className="fas fa-long-arrow-alt-left"
                                            style={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handlePostRemove}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          Selected: {postSelected.length}
                                        </Label>
                                        <ul
                                          // id="postSection"
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: 0,
                                            border: "1px solid black",
                                            height: "200px",
                                            overflow: "auto",
                                          }}>
                                          {postSelected.map((item, index) => {
                                            const isItemSelectedForRemoval =
                                              postRemoveSelectedItem.some(
                                                (post) => post.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  padding: "5px",
                                                  cursor: "pointer",
                                                  backgroundColor:
                                                    isItemSelectedForRemoval
                                                      ? "lightblue"
                                                      : "",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  handleRemovePostItemClick(
                                                    item
                                                  )
                                                }>
                                                {item.title}
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              )}
                            </Row>
                          )}

                          <Row className="mt-5">
                            <div className="m-3">
                              <h5
                                id="categoriesSection"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  fontSize: 18,
                                  color: !categoriesIsHide ? "black" : "gray",
                                }}
                                onClick={handelCategories}>
                                Categories{" "}
                                <i
                                  className={`mdi mdi-chevron-${
                                    categoriesIsHide ? "down" : "up"
                                  } mt-1`}
                                  style={{ fontSize: "22px" }}
                                />
                              </h5>
                            </div>
                            {!categoriesIsHide && (
                              <Row
                                // id="categoriesSection"
                                style={{
                                  marginLeft: "1px",
                                  padding: "10px",
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 2px 1px gray",
                                }}>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    marginTop: "17px",
                                  }}>
                                  <Col
                                    md="5"
                                    style={{
                                      display: "flex",
                                      margin: 0,
                                      padding: 0,
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        margin: 0,
                                        padding: 0,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                        gap: "5px",
                                      }}>
                                      <AvField
                                        name="categorysearch"
                                        placeholder="Search Category"
                                        type="search"
                                        // errorMessage="Enter Type of Search"
                                        className="form-control m-0"
                                        id="validationCustom04"
                                        onChange={handleCategorySearch}
                                        value={categorySearchTerm}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                  }}>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label "
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selection:{" "}
                                        {categoryFilteredSelection.length}
                                      </Label>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {categoryFilteredSelection.map(
                                          (item, index) => {
                                            const isCategoryAlreadySelected =
                                              categorySelected.some(
                                                (category) =>
                                                  category.id === item.id
                                              );
                                            const isItemSelected =
                                              categorySelectedItem.some(
                                                (category) =>
                                                  category.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  cursor:
                                                    isCategoryAlreadySelected
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  padding: "5px",
                                                  backgroundColor:
                                                    isItemSelected
                                                      ? "lightblue"
                                                      : "",
                                                  color:
                                                    isCategoryAlreadySelected
                                                      ? "gray"
                                                      : "black",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  !isCategoryAlreadySelected &&
                                                  handleCategoryItemClick(item)
                                                }>
                                                {item.category_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col
                                    md="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-right"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleCategorySelect}
                                        />
                                      </div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-left"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleCategoryRemove}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selected: {categorySelected.length}
                                      </Label>
                                      <ul
                                        // id="categoriesSection"
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {categorySelected.map((item, index) => {
                                          const isItemSelectedForRemoval =
                                            categoryRemoveSelectedItem.some(
                                              (category) =>
                                                category.id === item.id
                                            );
                                          return (
                                            <li
                                              key={index}
                                              style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor:
                                                  isItemSelectedForRemoval
                                                    ? "lightblue"
                                                    : "",
                                                marginBlock: "2px",
                                              }}
                                              onClick={() =>
                                                handleRemoveCategoryItemClick(
                                                  item
                                                )
                                              }>
                                              {item.category_name}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>
                            )}
                          </Row>

                          <Row className="mt-5">
                            <div className="m-3">
                              <h5
                                id="badgeSection"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  fontSize: 18,
                                  color: !badgeIsHide ? "black" : "gray",
                                }}
                                onClick={handelBadges}>
                                Badges{" "}
                                <i
                                  className={`mdi mdi-chevron-${
                                    badgeIsHide ? "down" : "up"
                                  } mt-1`}
                                  style={{ fontSize: "22px" }}
                                />
                              </h5>
                            </div>
                            {!badgeIsHide && (
                              <Row
                                // id="categoriesSection"
                                style={{
                                  marginLeft: "1px",
                                  padding: "10px",
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 2px 1px gray",
                                }}>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    marginTop: "17px",
                                  }}>
                                  <Col
                                    md="5"
                                    style={{
                                      display: "flex",
                                      margin: 0,
                                      padding: 0,
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        margin: 0,
                                        padding: 0,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                        gap: "5px",
                                      }}>
                                      <AvField
                                        name="categorysearch"
                                        placeholder="Search Badge"
                                        type="search"
                                        className="form-control m-0"
                                        id="validationCustom05"
                                        onChange={handleBadgesSearch}
                                        value={badgeSearchTerm}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                  }}>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label "
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selection:{" "}
                                        {badgeFilteredSelection.length}
                                      </Label>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {badgeFilteredSelection.map(
                                          (item, index) => {
                                            const isBadgeAlreadySelected =
                                              badgeSelected.some(
                                                (badge) => badge.id === item.id
                                              );
                                            const isItemSelected =
                                              badgeSelectedItem.some(
                                                (badge) => badge.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  cursor: isBadgeAlreadySelected
                                                    ? "not-allowed"
                                                    : "pointer",
                                                  padding: "5px",
                                                  backgroundColor:
                                                    isItemSelected
                                                      ? "lightblue"
                                                      : "",
                                                  color: isBadgeAlreadySelected
                                                    ? "gray"
                                                    : "black",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  !isBadgeAlreadySelected &&
                                                  handleBadgeItemClick(item)
                                                }>
                                                {item.name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col
                                    md="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-right"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleBadgeSelect}
                                        />
                                      </div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-left"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleBadgeRemove}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selected: {badgeSelected.length}
                                      </Label>
                                      <ul
                                        // id="categoriesSection"
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {badgeSelected.map((item, index) => {
                                          const isItemSelectedForRemoval =
                                            badgeRemoveSelectedItem.some(
                                              (badge) => badge.id === item.id
                                            );
                                          return (
                                            <li
                                              key={index}
                                              style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor:
                                                  isItemSelectedForRemoval
                                                    ? "lightblue"
                                                    : "",
                                                marginBlock: "2px",
                                              }}
                                              onClick={() =>
                                                handleRemoveBadgeItemClick(item)
                                              }>
                                              {item.name}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>
                            )}
                          </Row>

                          <Row className="mt-5">
                            <div className="m-3">
                              <h5
                                id="keywordSection"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  fontSize: 18,
                                  color: !keywordIsHide ? "black" : "gray",
                                }}
                                onClick={handelKeyword}>
                                Keywords{" "}
                                <i
                                  className={`mdi mdi-chevron-${
                                    keywordIsHide ? "down" : "up"
                                  } mt-1`}
                                  style={{ fontSize: "22px" }}
                                />
                              </h5>
                            </div>
                            {!keywordIsHide && (
                              <Row
                                style={{
                                  marginLeft: "1px",
                                  padding: "10px",
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 2px 1px gray",
                                }}>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    marginTop: "17px",
                                  }}>
                                  <Col
                                    md="5"
                                    style={{
                                      display: "flex",
                                      margin: 0,
                                      padding: 0,
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        margin: 0,
                                        padding: 0,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                        gap: "5px",
                                      }}>
                                      <AvField
                                        name="badgesearch"
                                        placeholder="Search Keyword"
                                        type="search"
                                        className="form-control m-0"
                                        id="validationCustom05"
                                        onChange={handleKeywordsSearch}
                                        value={keywordSearchTerm}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                  }}>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label "
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selection:{" "}
                                        {keywordFilteredSelection.length}
                                      </Label>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {keywordFilteredSelection.map(
                                          (item, index) => {
                                            const isKeywordAlreadySelected =
                                              keywordSelected.some(
                                                (keyword) =>
                                                  keyword.id === item.id
                                              );
                                            const isItemSelected =
                                              KeywordSelectedItem.some(
                                                (keyword) =>
                                                  keyword.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  cursor:
                                                    isKeywordAlreadySelected
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  padding: "5px",
                                                  backgroundColor:
                                                    isItemSelected
                                                      ? "lightblue"
                                                      : "",
                                                  color:
                                                    isKeywordAlreadySelected
                                                      ? "gray"
                                                      : "black",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  !isKeywordAlreadySelected &&
                                                  handleKeywordItemClick(item)
                                                }>
                                                {item.keyword_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col
                                    md="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-right"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleKeywordSelect}
                                        />
                                      </div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-left"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleKeywordRemove}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selected: {keywordSelected.length}
                                      </Label>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {keywordSelected.map((item, index) => {
                                          const isItemSelectedForRemoval =
                                            KeywordRemoveSelectedItem.some(
                                              (keyword) =>
                                                keyword.id === item.id
                                            );
                                          return (
                                            <li
                                              key={index}
                                              style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor:
                                                  isItemSelectedForRemoval
                                                    ? "lightblue"
                                                    : "",
                                                marginBlock: "2px",
                                              }}
                                              onClick={() =>
                                                handleRemoveKeywordItemClick(
                                                  item
                                                )
                                              }>
                                              {item.keyword_name}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>
                            )}
                          </Row>
                        </Row>

                        <Row className="mt-4">
                          <Col lg="4">
                            <div
                              style={{ display: "inline-flex", gap: "14px" }}>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.inputData.shareable}
                                  id="invalid_Check"
                                  name="shareable"
                                  // disabled={!!selectedTenant}
                                  checked={formData.inputData.shareable}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalid_Check">
                                  Is Shareable
                                </label>
                              </div>

                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.inputData.is_active}
                                  id="invalidCheck"
                                  name="is_active"
                                  checked={formData.inputData.is_active}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck">
                                  Is Active
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>

                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={() => navigate("/academic-channels-list")}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AcademicChannelsCreate;
