// //  Static Menu
// import React, { Component } from "react";

// // MetisMenu
// import MetisMenu from "metismenujs";
// // import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";

// //i18n
// import { withTranslation } from "react-i18next";

// import { connect } from "react-redux";
// import {
//   changeLayout,
//   changeLayoutWidth,
//   changeSidebarTheme,
//   changeSidebarType,
//   changePreloader,
// } from "../../store/actions";
// import withRouter from "../Common/withRouter";

// class SidebarContent extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       pathName: this.props.router.location.pathname,
//       menuData: [],
//       accesMenuData: [],
//       activeMenuId: null,
//     };
//   }


//   componentDidMount() {
//     this.initMenu();
//   }

//   UNSAFE_componentDidUpdate(prevProps, prevState) {
//     if (prevProps !== this.props) {
//       if (this.props.type !== prevProps.type) {
//         this.initMenu();
//       }
//     }
//     if (
//       this.props.router.location.pathname !== prevProps.router.location.pathname
//     ) {
//       this.setState({ pathName: this.props.router.location.pathname }, () => {
//         this.initMenu();
//       });
//       window.scrollTo({ top: 0, behavior: "smooth" });
//     }
//     // Ensure menu is reinitialized on state change
//     if (
//       prevState.menuData !== this.state.menuData ||
//       prevState.accesMenuData !== this.state.accesMenuData
//     ) {
//       this.initMenu();
//     }
//   }

//     initMenu() {
//       // Remove any existing active classes
//       const ul = document.getElementById("side-menu");
//       if (ul) {
//         const items = ul.getElementsByTagName("a");
//         for (let i = 0; i < items.length; ++i) {
//           items[i].classList.remove("active");
//           const parent = items[i].parentElement;
//           if (parent) {
//             parent.classList.remove("mm-active");
//             const parent2 = parent.parentElement;
//             if (parent2) {
//               parent2.classList.remove("mm-show");
//               const parent3 = parent2.parentElement;
//               if (parent3) {
//                 parent3.classList.remove("mm-active");
//                 const parent4 = parent3.parentElement;
//                 if (parent4) {
//                   parent4.classList.remove("mm-active");
//                 }
//               }
//             }
//           }
//         }
//       }

//       // Initialize MetisMenu
//       new MetisMenu("#side-menu");
//     }

//     activateParentDropdown = (item) => {
//       item.classList.add("active");
//       const parent = item.parentElement;

//       if (parent) {
//         parent.classList.add("mm-active");
//         const parent2 = parent.parentElement;

//         if (parent2) {
//           parent2.classList.add("mm-show");

//           const parent3 = parent2.parentElement;

//           if (parent3) {
//             parent3.classList.add("mm-active"); // li
//             parent3.childNodes[0].classList.add("mm-active"); // a
//             const parent4 = parent3.parentElement;
//             if (parent4) {
//               parent4.classList.add("mm-active");
//             }
//           }
//         }
//         return false;
//       }
//       return false;
//     };

//   render() {
    
//     // const { pathName } = this.state;
//     const pathName = this.props.router.location.pathname;

//     // Define the inline style for the active sub-menu item
//     const activeStyle = {
//       color: "white",
//     };
//     return (
//       <React.Fragment>
//         <div id="sidebar-menu">
//           <ul className="metismenu list-unstyled" id="side-menu">
//             <li className="menu-title">{this.props.t("Menu")}</li>
//             <li>
//               <Link
//                 to="/dashboard"
//                 className="waves-effect"
//                 // style={pathName === "/dashboard" ? activeStyle : {}}
//               >
//                 <i className="ri-dashboard-line"></i>
//                 <span className="badge rounded-pill bg-success float-end">
//                   3
//                 </span>
//                 <span className="ms-1">{this.props.t("Dashboard")}</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">{this.props.t("Foundation")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/environment-list"
//                     style={pathName === "/environment-list" ? activeStyle : {}}>
//                     {this.props.t("Environment")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/role-list"
//                     style={pathName === "/role-list" ? activeStyle : {}}>
//                     {this.props.t("Role")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/tenant-list"
//                     style={pathName === "/tenant-list" ? activeStyle : {}}>
//                     {this.props.t("Tenant")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/service-list"
//                     style={pathName === "/service-list" ? activeStyle : {}}>
//                     {this.props.t("Service")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/feature-list"
//                     style={pathName === "/feature-list" ? activeStyle : {}}>
//                     {this.props.t("Feature")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/service-mapping-list"
//                     style={
//                       pathName === "/service-mapping-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Service Mapping")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/feature-mapping-list"
//                     style={
//                       pathName === "/feature-mapping-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Feature Mapping")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/share-code-limits-list"
//                     style={
//                       pathName === "/share-code-limits-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Share Code Limits")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">{this.props.t("Thread")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/thread-paticipant-limit-list"
//                     style={
//                       pathName === "/thread-paticipant-limit-list"
//                         ? activeStyle
//                         : {}
//                     }>
//                     {this.props.t("Thread Paticipant Limit")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/thread-category-list"
//                     style={
//                       pathName === "/thread-category-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Thread Category")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">{this.props.t("User Management")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/tenant-user-list"
//                     style={pathName === "/tenant-user-list" ? activeStyle : {}}>
//                     {this.props.t("Tenant User")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/cms-user-list"
//                     style={pathName === "/cms-user-list" ? activeStyle : {}}>
//                     {this.props.t("CMS User")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/guest-user-list"
//                     style={pathName === "/guest-user-list" ? activeStyle : {}}>
//                     {this.props.t("Guest User")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/ibo-user-list"
//                     style={pathName === "/ibo-user-list" ? activeStyle : {}}>
//                     {this.props.t("IBO User")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/manage-group-id-list"
//                     style={
//                       pathName === "/manage-group-id-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Manage Group Id")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/manage-audience-id-list"
//                     style={
//                       pathName === "/manage-audience-id-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Manage Audience Id")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/designation-list"
//                     style={pathName === "/designation-list" ? activeStyle : {}}>
//                     {this.props.t("Designation")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/mega-hub-list"
//                     style={pathName === "/mega-hub-list" ? activeStyle : {}}>
//                     {this.props.t("Mega Hub List")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/hub-list"
//                     style={pathName === "/hub-list" ? activeStyle : {}}>
//                     {this.props.t("Hub List")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">
//                   {this.props.t("Application Settings")}
//                 </span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/country-list"
//                     style={pathName === "/country-list" ? activeStyle : {}}>
//                     {this.props.t("Country")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/mobile-app-settings-list"
//                     style={
//                       pathName === "/mobile-app-settings-list"
//                         ? activeStyle
//                         : {}
//                     }>
//                     {this.props.t("Mobile App Settings")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/integration-configuration-list"
//                     style={
//                       pathName === "/integration-configuration-list"
//                         ? activeStyle
//                         : {}
//                     }>
//                     {this.props.t("Integration Configuration")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/terms-and-conditions-list"
//                     style={
//                       pathName === "/terms-and-conditions-list"
//                         ? activeStyle
//                         : {}
//                     }>
//                     {this.props.t("Terms and Conditions")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/privacy-policy-list"
//                     style={
//                       pathName === "/privacy-policy-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Privacy Policy")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/about-app-list"
//                     style={pathName === "/about-app-list" ? activeStyle : {}}>
//                     {this.props.t("About Us")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">{this.props.t("Social")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/post-type-list"
//                     style={pathName === "/post-type-list" ? activeStyle : {}}>
//                     {this.props.t("Post Type")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/post-sub-type-list"
//                     style={
//                       pathName === "/post-sub-type-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Post Sub Type")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/post-hashtag-list"
//                     style={
//                       pathName === "/post-hashtag-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Post Hashtag")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/resource-list"
//                     style={pathName === "/resource-list" ? activeStyle : {}}>
//                     {this.props.t("Resource")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/post-color" style={pathName === "/post-color" ? activeStyle : {}}>
//                     {this.props.t("Post Color")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">{this.props.t("Chat Folder")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/tag-list"
//                     style={pathName === "/tag-list" ? activeStyle : {}}>
//                     {this.props.t("Tag")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">{this.props.t("Administrator")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/offline-download-storage-period-list"
//                     style={
//                       pathName === "/offline-download-storage-period-list"
//                         ? activeStyle
//                         : {}
//                     }>
//                     {this.props.t("Offline Download Storage Period")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/feature-gamification-rules-list"
//                     style={
//                       pathName === "/feature-gamification-rules-list"
//                         ? activeStyle
//                         : {}
//                     }>
//                     {this.props.t("Feature Gamification Rules")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/badges-list"
//                     style={pathName === "/badges-list" ? activeStyle : {}}>
//                     {this.props.t("Badges")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/user-access-list"
//                     style={pathName === "/user-access-list" ? activeStyle : {}}>
//                     {this.props.t("User Access")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/default-images-list"
//                     style={
//                       pathName === "/default-images-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Academic & Content Default Image")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">{this.props.t("Academics")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/manage-channels-list"
//                     style={
//                       pathName === "/manage-channels-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Manage Channels")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/manage-academics-list"
//                     style={
//                       pathName === "/manage-academics-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Manage Module")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/manage-post-list"
//                     style={pathName === "/manage-post-list" ? activeStyle : {}}>
//                     {this.props.t("Manage Post")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/academic-category-list"
//                     style={
//                       pathName === "/academic-category-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Academic Category")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/academic-banner-list"
//                     style={
//                       pathName === "/academic-banner-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Academic Banner")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/academic-tag-list"
//                     style={
//                       pathName === "/academic-tag-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Academic Tag")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-pencil-ruler-2-line"></i>
//                 <span className="ms-1">{this.props.t("Contents")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link
//                     to="/manage-content-list"
//                     style={
//                       pathName === "/manage-content-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Manage Channel")}
//                   </Link>
//                 </li>
//                 {/* <li>
//                   <Link to="/manage-module-list" style={pathName === "/manage-module-list" ? activeStyle : {}}>
//                     {this.props.t("Manage Module")}
//                   </Link>
//                 </li> */}
//                 <li>
//                   <Link
//                     to="/content-manage-post-list"
//                     style={
//                       pathName === "/content-manage-post-list"
//                         ? activeStyle
//                         : {}
//                     }>
//                     {this.props.t("Manage Post")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/content-category-list"
//                     style={
//                       pathName === "/content-category-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Content Category")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/content-banner-list"
//                     style={
//                       pathName === "/content-banner-list" ? activeStyle : {}
//                     }>
//                     {this.props.t("Content Banner")}
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//           </ul>
//         </div>
//       </React.Fragment>
//     );
//   }
// }

// const mapStatetoProps = (state) => {
//   return { ...state.Layout };
// };

// export default withRouter(
//   connect(mapStatetoProps, {
//     changeLayout,
//     changeSidebarTheme,
//     changeSidebarType,
//     changeLayoutWidth,
//     changePreloader,
//   })(withTranslation()(SidebarContent))
// );

//Dynamic Menu

import React, { Component } from "react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import withRouter from "../Common/withRouter";
import { fetchData, user } from "../../services/fetchData";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: this.props.router.location.pathname,
      menuData: [],
      accesMenuData: [],
      activeMenuId: null,
    };
  }

  componentDidMount() {
    Promise.all([this.getMenuData(), this.getAccessMenuData()]).then(() => {
      this.initMenu();
    });
  }

  async getMenuData() {
    try {
      const payload = {
        modelName: "menu_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code === 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({ menuData: responseData.data }, () => {
          this.initMenu();
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async getAccessMenuData() {
    try {
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      const payload = {
        modelName: "access_controls",
        whereCondition: {
          user_id: authID[0].id,
          access: 1,
          is_active: 1,
        },
        pagination: {
          page: 1,
          pageSize: 1000000,
        },
      };
      const responseData = await fetchData("getMasterList", payload, user);
      if (responseData.code === 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({ accesMenuData: responseData.data }, () => {
          this.initMenu();
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  UNSAFE_componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
    if (
      this.props.router.location.pathname !== prevProps.router.location.pathname
    ) {
      this.setState({ pathName: this.props.router.location.pathname }, () => {
        this.initMenu();
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    // Ensure menu is reinitialized on state change
    if (
      prevState.menuData !== this.state.menuData ||
      prevState.accesMenuData !== this.state.accesMenuData
    ) {
      this.initMenu();
    }
  }

  initMenu() {
    // Remove any existing active classes
    const ul = document.getElementById("side-menu");
    if (ul) {
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        items[i].classList.remove("active");
        const parent = items[i].parentElement;
        if (parent) {
          parent.classList.remove("mm-active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.remove("mm-show");
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.remove("mm-active");
              const parent4 = parent3.parentElement;
              if (parent4) {
                parent4.classList.remove("mm-active");
              }
            }
          }
        }
      }
    }

    // Initialize MetisMenu
    new MetisMenu("#side-menu");
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); // a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    const { t } = this.props;
    const { menuData, accesMenuData } = this.state;
    const accessMenuId = accesMenuData.map((item) => item.menu_id);
    const newfilterMenuData = menuData.filter((item) =>
      accessMenuId.includes(item.id)
    );

    // Get the current pathname
    const currentPath = this.props.router.location.pathname;

    // Define the inline style for the active sub-menu item
    const activeStyle = {
      color: "white",
    };

    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ms-1">{this.props.t("Dashboard")}</span>
              </Link>
            </li>
            {menuData
              .filter((item) =>
                newfilterMenuData.some((child) => child.parent_id == item.id)
              )
              .sort((a, b) => a.sequence - b.sequence)
              .map((item, index) => (
                <li key={index}>
                  <Link
                    style={currentPath === item.menu_url ? activeStyle : {}}
                    to={item.menu_url}
                    className={`has-arrow waves-effect`}>
                    <i className={item.web_menu_icon}></i>
                    <span className="ms-1">{t(item.menu_name)}</span>
                  </Link>

                  <ul className="sub-menu">
                    {newfilterMenuData
                      .filter((child) => child.parent_id === item.id)
                      .sort((a, b) => a.sequence - b.sequence)
                      .map((child) => (
                        <li key={child.id}>
                          <Link
                            style={
                              currentPath === child.menu_url ? activeStyle : {}
                            }
                            to={child.menu_url}>
                            {t(child.menu_name)}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withTranslation()(SidebarContent))
);






