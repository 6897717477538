import React, { useMemo, useState, useEffect ,useRef} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";


const breadcrumbItems = [];

const PostReportingOptionsList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const payload = {
        modelName: "report_masters",
        whereCondition: {
          is_deleted: 0,
        },
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);

        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Type",
        accessor: (row) =>
          row.type && row.type == 1
            ? "post"
            : row.type == 2
            ? "post comment"
            : row.type == 3
            ? "user"
            : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Option",
        accessor: (row) => (row.option && row.option ? row.option : "-"),
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );
  const handleClickAdd = () => {
    navigate("/report-create");
  };

  const handleEditClick = (id) => {
    navigate(`/report-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
      if (isToastOpenRef.current) return;
      isToastOpenRef.current = true;
    const onConfirm = async () => {
      const payload = {
        id: id,
        modelName: "report_masters",
        inputData: {
          is_deleted: 1,
        },
      };

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData && responseData.code === 1) {
        toast.success("Post Reporting Options Deleted!");
        getData();
      } else {
        toast.error("Error while deleting the Post Reporting Options.");
      }
      isToastOpenRef.current = false;

    };

    const onCancel = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;

    };

    const onClose = () => {
      isToastOpenRef.current = false;
        
      };

    showConfirmationToast(
      "Are you sure you want to delete this Post Reporting Options?",
      onConfirm,
      onCancel,
      onClose
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Post Reporting Options List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Post Reporting Options
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PostReportingOptionsList;
