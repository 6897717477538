import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  dropdownData,
  fetchData,
  image_url,
  academics,
  downloadBlobData,
} from "../../../services/fetchData";
import Select from "react-select";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";

const BadgeCreate = () => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [tenantError, setTenantError] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [src, setSrc] = useState("");
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] =
    useState(false);
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(false);

  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: "",
      name: "",
      image: "",
      points: "",
      description: "",
      is_deleted: 0,
      is_active: 1,
    },

    uploadImage: {
      field_name: "image",
      folderPath: "",
      imagePath: "",
    },
  });

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.image) {
        setSrc(image_url + data.image);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      let payload = {
        modelName: "badge_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload, academics);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getTenantDropdownData(data.tenant_id);
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      if (authID.type == 1 && tenantId) {
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        payload.whereCondition.id = authID.tenant_id;
      }

      const responseData = await fetchData("getMasterList", payload);
      const dropdownResponseData = await dropdownData(
        responseData.data,
        "tenant_company_name"
      );
      if (id) {
        dropdownResponseData[0].options.forEach((element) => {
          if (element.value == id) {
            setSelectedTenant(element);
          }
        });
      }
      setTenantlist(dropdownResponseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTenantSelectChange = (selectedTenant) => {
    setSelectedTenant(selectedTenant);
    setTenantError(!selectedTenant);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedTenant ? selectedTenant.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFileTypes = [
        "image/svg+xml",
        "image/png",
        "image/jpeg",
        "image/jpg",
      ];
      if (!validFileTypes.includes(file.type)) {
        toast.error("Select a valid image format (SVG, PNG, JPEG, JPG).");
        e.target.value = "";
        return;
      }

      const reader = new FileReader();

      // Handle SVG files
      if (file.type === "image/svg+xml") {
        reader.onloadend = () => {
          const svgData = reader.result;
          const base64Data = window.btoa(svgData);
          const base64Svg = `data:image/svg;base64,${base64Data}`;

          setFormData((prevState) => ({
            ...prevState,
            uploadImage: {
              ...prevState.uploadImage,
              imagePath: base64Svg,
            },
          }));
          setSrc(`data:image/svg+xml;base64,${base64Data}`);
           setIsMediaRemoveAndDownload(true);
        };
        reader.readAsText(file);
      } else {
        // Handle PNG, JPEG, JPG files
        reader.onloadend = () => {
          const base64Data = reader.result;

          setFormData((prevState) => ({
            ...prevState,
            uploadImage: {
              ...prevState.uploadImage,
              imagePath: base64Data,
            },
          }));
          setSrc(base64Data);
          setIsMediaRemoveAndDownload(true)
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDownloadMediaClick = async (mediaFile) => {
    try {
      setMediaImageLoadingData(true);
      const urlSegments = mediaFile.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];
      await downloadBlobData(academics, lastSegment);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setMediaImageLoadingData(false);
    }
  };

  const handleRemoveMediaClick = () => {
    setSrc("");
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        image: "",
      },
    }));
  };

  const validateForm = () => {
    const { api_url } = formData;
    const apiUrlRegex = /^(https?:\/\/(www\.)?|www\.)[^\s/$.?#].[^\s]*$/;

    if (!apiUrlRegex.test(api_url)) {
      toast.error("Enter a valid API url");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    !selectedTenant ? setTenantError(true) : setTenantError(false);
    if (!formData.inputData.name || !formData.inputData.points) {
      return toast.warning("All fields are required");
    }

    // if (!validateForm()) {
    //   return;
    // }

    try {
      setLoadingData(true);

      var payload = {
        modelName: "badge_masters",
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (id) {
        payload.id = id;
      }
      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        academics
      );
      if (responseData.code == 1) {
        toast.success("Academic Badge saved successfully!");
        navigate("/badges-list");
      } else {
        toast.error(responseData.message);
        // navigate("/badges-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE ACADEMIC BADGE"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      className="needs-validation"
                      id="tooltipForm"
                      onSubmit={handleSubmit}>
                      <>
                        {" "}
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Tenant
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedTenant}
                                onChange={handleTenantSelectChange}
                                options={tenantlist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {tenantError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "#ff4d4d",
                                    }}>
                                    Select Tenant
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Name/Title
                                <Asterisk />
                              </Label>
                              <AvField
                                name="name"
                                placeholder="Name/Title"
                                type="text"
                                errorMessage="Enter FName/Title"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.inputData.name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Badge Points
                                <Asterisk />
                              </Label>
                              <AvField
                                name="points"
                                placeholder="Badge Points"
                                type="number"
                                errorMessage="Enter Badge Points"
                                className="form-control"
                                min="1"
                                validate={{ required: { value: true } }}
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.inputData.points}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Description
                              </Label>
                              <AvField
                                name="description"
                                placeholder="Description "
                                type="textarea"
                                errorMessage="Enter Description"
                                rows="3"
                                onChange={handleTextChange}
                                value={formData.inputData.description}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">Badge Image</Label>
                              <input
                                name="imagePath"
                                type="file"
                                className="form-control"
                                id="customFile"
                                accept="image/svg+xml, image/png, image/jpeg, image/jpg"
                                onChange={handleFileChange}
                              />
                            </div>
                            {src && (
                              <Col md="6" className="mt-1">
                                <div className="mb-3 d-flex">
                                  <img
                                    src={src}
                                    alt="image"
                                    width="120px"
                                    height="100px"
                                    style={{
                                      border: ".5px solid gray",
                                      borderRadius: "5px",
                                    }}
                                  />
                                  {!isMediaRemoveAndDownload && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "10px",
                                      }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "15px",
                                        }}>
                                        {loadingMediaImageData ? (
                                          <LoaderDownloadMedia />
                                        ) : (
                                          <img
                                            src={downloadIcon}
                                            alt="download"
                                            width="30px"
                                            height="30px"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDownloadMediaClick(src)
                                            }
                                          />
                                        )}
                                        <img
                                          src={removeIcon}
                                          alt="remove"
                                          width="30px"
                                          height="30px"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={handleRemoveMediaClick}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.inputData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className="waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className="waves-effect waves-light me-1"
                          onClick={() => navigate("/badges-list")}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BadgeCreate;
