import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { dropdownData, fetchData } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const PostContentLimitsCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [breadcrumbItems] = useState([]);
  const [tenantlist, setTenantlist] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantError, setTenantError] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: "",
      limit: Number(),
      is_deleted: 0,
      is_active: 1,
    },
  });

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      let payload = {
        modelName: "post_content_limits",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        const data = responseData.data[0];
        if (id) {
          getTenantDropdownData(data.tenant_id);
          setFormData((prevState) => ({
            ...prevState,
            inputData: {
              ...prevState.inputData,
              ...data,
            },
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedOption) => {
    setSelectedTenant(selectedOption);
    setTenantError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    !selectedTenant ? setTenantError(true) : setTenantError(false);

    if (!selectedTenant || !formData.inputData.limit) {
      return toast.warning("All fields are required");
    }
    try {
      setLoadingData(true);
      var payload = {
        modelName: "post_content_limits",
        inputData: formData.inputData,
      };

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success(responseData.message);
        navigate("/post-content-limit-list");
      } else {
        toast.error(responseData.message);
        navigate("/post-content-limit-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE POST CONTENT LIMIT"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        {" "}
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Tanent
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedTenant}
                                onChange={handleSelectTenantChange}
                                options={tenantlist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {tenantError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "#ff4d4d",
                                    }}>
                                    Select Tenant
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                limit
                                <Asterisk />
                              </Label>
                              <AvField
                                name="limit"
                                placeholder="Limit"
                                type="number"
                                min={"1"}
                                errorMessage="Enter Limit"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.limit}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={() => navigate("/post-content-limit-list")}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PostContentLimitsCreate;
