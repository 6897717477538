import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/fetchData";

const breadcrumbItems = [];

const ManageGroupIdList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [hubId, setHubId] = useState([]);
  const [megaHubId, setMegaHubId] = useState([]);

  useEffect(() => {
    getData();
  }, [hubId, megaHubId, searchKeyword]);

  // get listing data
  const getData = async () => {
      try {
        let tenantId = localStorage.getItem("tenantId");
        let authID = localStorage.getItem("logedin-user");
        authID = JSON.parse(authID);
        authID = authID[0];

        const payload = {
          modelName: "group_id_masters",
          relations: [
            {
              module: "tenant_masters",
              moduleas: "tenant_masters",
            },
            {
              module: "mega_hub_masters",
              moduleas: "mega_hub_masters",
            },
            {
              module: "hub_masters",
              moduleas: "hub_masters",
            },
          ],
          search: {
            field_name: ["group_name"],
            searchKeyword: searchKeyword,
          },
          whereCondition: {
            is_active: 1,
          },
        };
        if (authID.type == 1 && tenantId) {
          payload.whereCondition.id = tenantId;
        }

        if (authID.type == 2) {
          payload.whereCondition.tenant_id = authID.tenant_id;
        }
        if (hubId.length > 0) {
          payload.whereCondition.hub_id = hubId;
        }
        if (megaHubId.length > 0) {
          payload.whereCondition.mega_hub_id = megaHubId;
        }
        const responseData = await fetchData("getMasterList", payload);

        if (responseData.code == 1) {
          responseData.data.forEach((element, index) => {
            element.index = index + 1;
          });
          responseData.data = responseData.data.sort(
            (a, b) => b.index - a.index
          );

          setData(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
  };

  const handleClickAdd = () => {
    navigate("/manage-group-id-create");
  };

  const handleEditClick = (id) => {
    navigate(`/manage-group-id-create/${id}`);
  };

  const getHubId = (hubId) => {
    setHubId(hubId);
  };

  const getMegaHubId = (megaHubId) => {
    setMegaHubId(megaHubId);
  };

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Tenant Name",
        accessor: (row) =>
          row.tenant_masters && row.tenant_masters.tenant_company_name
            ? row.tenant_masters.tenant_company_name
            : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Mega Hub Name",
        accessor: (row) =>
          row.mega_hub_masters && row.mega_hub_masters.hub_name
            ? row.mega_hub_masters.hub_name
            : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Hub Name",
        accessor: (row) =>
          row.hub_masters && row.hub_masters.hub_name
            ? row.hub_masters.hub_name
            : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Group ID",
        accessor: (row) =>
          row.llc_group_id && row.llc_group_id ? row.llc_group_id : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Group Name",
        accessor: (row) =>
          row.group_name && row.group_name ? row.group_name : "-",
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="primary"
            onClick={() => handleEditClick(row.original.id)}>
            <i class="fas fa-edit"></i>
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Manage Group Id List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Manage Group Id
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onHubDropselect={getHubId}
                onMegaHubDropselect={getMegaHubId}
                onSearchInputData={getSearchInputData}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageGroupIdList;
