import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/fetchData";

const breadcrumbItems = [];

const FeatureActionList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceId, setServiceId] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    getData();
  }, [serviceId, searchKeyword]);

  // get listing data
  const getData = async () => {
    try {
      const payload = {
        modelName: "features_masters",
        relations: [
          {
            module: "service_masters",
            moduleas: "service_masters",
          },
        ],

        search: {
          field_name: ["feature_name", "label"],
          searchKeyword: searchKeyword,
        },

        whereCondition: {
          is_active: 1,
        },
      };

      if (serviceId.length > 0) {
        payload.whereCondition.service_id = serviceId;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };


   const handleEditClick = (id) => {
     navigate(`/feature-create/${id}`);
   };

   const getServiceId = (serviceId) => {
     setServiceId(serviceId);
   };

   const getSearchInputData = (inputValue) => {
     setSearchKeyword(inputValue);
   };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Service  Name",
        accessor: (row) =>
          row.service_masters && row.service_masters.service_name
            ? row.service_masters.service_name
            : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Feature Action Name",
        accessor: (row) =>
          row.feature_name && row.feature_name ? row.feature_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Label",
        accessor: (row) => (row.label && row.label ? row.label : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="primary"
            onClick={() => handleEditClick(row.original.id)}>
            <i class="fas fa-edit"></i>
          </Button>
        ),
      },
    ],
    []
  );

 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Feature Action List"
              breadcrumbItems={breadcrumbItems}
            />
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onServiceDropselect={getServiceId}
                onSearchInputData={getSearchInputData}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FeatureActionList;
