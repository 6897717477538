import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  downloadBlobData,
  fetchData,
  image_url,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";

const TenantCreate = ({ router }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [breadcrumbItems] = useState([]);
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [fetchedData, setFetchedData] = useState(null);
  const [src, setSrc] = useState("");
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] =
    useState(false);
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(false);

  const [formData, setFormData] = useState({
    inputData: {
      tenant_company_name: "",
      contact_person_name: "",
      contact_person_number: "",
      contact_person_email: "",
      website: "",
      address: "",
      is_active: 1,
    },
    uploadImage: {
      imagePath: "",
      folderPath: "",
      field_name: "logo",
    },
  });

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.logo) {
        setSrc(image_url + data.logo);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          // is_active: 1,
          id: id,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      console.log("responseData", responseData);

      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const validateForm = () => {
    const { contact_person_number, website, contact_person_email } =
      formData.inputData;
    const phoneRegex = /^\d{10,12}$/;
    const websiteRegex =
      /^(https?:\/\/(?:www\.)?|www\.)[^\s/$.?#].[^\s]*\.(com|net|org|gov|edu|io)(\/[^\s]*)?$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(contact_person_number)) {
      toast.error("Please enter a valid number.");
      return false;
    }

    if (!websiteRegex.test(website)) {
      toast.error("Please enter a valid website URL.");
      return false;
    }

    if (!emailRegex.test(contact_person_email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  // handle File Change ( uplaod Images )
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setSrc(reader.result);
        setIsMediaRemoveAndDownload(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownloadMediaClick = async (mediaFile) => {
    try {
      setMediaImageLoadingData(true);
      const urlSegments = mediaFile.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];
      await downloadBlobData("", lastSegment);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setMediaImageLoadingData(false);
    }
  };

  // handle media remove
  const handleRemoveMediaClick = () => {
    setSrc("");
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        logo: "",
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.inputData.tenant_company_name ||
      !formData.inputData.contact_person_name ||
      !formData.inputData.contact_person_email ||
      !formData.inputData.website ||
      !formData.inputData.address
    ) {
      return toast.warning("All fields are required");
    }

    if (!validateForm()) {
      return;
    }

    try {
      setLoadingData(true);
      var payload = {
        modelName: "tenant_masters",
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Tenant saved successfully!");
        window.location.reload();
        navigate("/tenant-list");
      } else {
        toast.error("Error While saving Tenant!");
        navigate("/tenant-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/tenant-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE TENANT"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      id="tooltipForm"
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="tenant_company_name">
                                Tenant Company Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="tenant_company_name"
                                placeholder="Tenant Company name"
                                type="text"
                                errorMessage="Enter Tenant Company Name"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.tenant_company_name}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="contact_person_name">
                                Contact Person Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="contact_person_name"
                                placeholder="Contact Person name"
                                type="text"
                                errorMessage="Enter Contact Person Name"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.contact_person_name}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="contact_person_number">
                                Contact Person Number
                                {/* <Asterisk /> */}
                              </Label>
                              <AvField
                                name="contact_person_number"
                                placeholder="Contact Person Number"
                                type="phone"
                                errorMessage="Enter Contact Person Number"
                                className="form-control"
                                // validate={{
                                //   required: { value: true },
                                //   pattern: {
                                //     value: "^[0-9]{10,12}$",
                                //     errorMessage:
                                //       "Phone number must be between 10 and 12 digits",
                                //   },
                                // }}
                                value={formData.inputData.contact_person_number}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="contact_person_email">
                                Contact Person Email
                                <Asterisk />
                              </Label>
                              <AvField
                                name="contact_person_email"
                                placeholder="Contact Person Email"
                                type="email"
                                errorMessage="Enter Contact Person Email"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value:
                                      "/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/",
                                    errorMessage: "Enter Valid Email",
                                  },
                                }}
                                value={formData.inputData.contact_person_email}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="website">
                                Website
                                <Asterisk />
                              </Label>
                              <AvField
                                name="website"
                                placeholder="Website name"
                                type="text"
                                errorMessage="Enter Website Name"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value:
                                      "^(https?://(?:www\\.)?|www\\.)[^\\s/$.?#].[^\\s]*\\.(com|net|org|gov|edu|io)(/[^\\s]*)?$",
                                    errorMessage:
                                      "Enter a valid URL starting with 'https://' or 'www.' and ending with a valid domain.",
                                  },
                                }}
                                value={formData.inputData.website}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="address">
                                Address
                                <Asterisk />
                              </Label>
                              <AvField
                                name="address"
                                placeholder="Address name"
                                type="textarea"
                                errorMessage="Enter Address Name"
                                className="form-control"
                                rows="3"
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.address}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Logo
                              </Label>
                              <div className="input-grosup">
                                <input
                                  type="file"
                                  className="form-control"
                                  id="customFile"
                                  onChange={handleFileChange}
                                />
                              </div>
                              {src && (
                                <Col md="6" className="mt-1 d-flex">
                                  <img
                                    src={src}
                                    alt="logo"
                                    width="120px"
                                    height="100px"
                                    style={{
                                      border: ".5px solid gray",
                                      borderRadius: "5px",
                                    }}
                                  />
                                  {!isMediaRemoveAndDownload && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "10px",
                                      }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "15px",
                                        }}>
                                        {loadingMediaImageData ? (
                                          <LoaderDownloadMedia />
                                        ) : (
                                          <img
                                            src={downloadIcon}
                                            alt="download"
                                            width="30px"
                                            height="30px"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDownloadMediaClick(src)
                                            }
                                          />
                                        )}
                                        <img
                                          src={removeIcon}
                                          alt="remove"
                                          width="30px"
                                          height="30px"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={handleRemoveMediaClick}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Col>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.inputData.is_active}
                                id="is_active"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="is_active">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TenantCreate;
