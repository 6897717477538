import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";

// users
import avatar9 from "../../../assets/images/users/avatar-9.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { image_url } from "./../../../services/fetchData";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("");
  const [profileImg, setPprofileImg] = useState("");
  const navigate = useNavigate();

  const toggle = () => {
    setMenu((prevState) => !prevState);
  };

  const handelLogout = () => {
    if (localStorage.getItem("logedin-user")) {
      localStorage.removeItem("logedin-user");
      localStorage.removeItem("tenantId");
      toast.success("Logout Successfully");
      navigate("/");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("logedin-user")) {
      let obj = JSON.parse(localStorage.getItem("logedin-user"));
      setPprofileImg(obj[0].image);
      let uNm = obj[0].username;
      setUsername(uNm.charAt(0).toUpperCase() + uNm.slice(1));
    }
  }, []);


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block user-dropdown">
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown">
          <img
            style={{
              border: ".6px solid gray",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            className="rounded-circle header-profile-user me-1"
            src={!profileImg ? avatar9 : image_url + profileImg}
          />
          <span className="d-none d-xl-inline-block ms-1 text-transform">
            {username}
          </span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={handelLogout}>
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
            {props.t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(ProfileMenu);
