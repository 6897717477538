import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { dropdownData, fetchData } from "../../../services/fetchData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServiceMapping = () => {
  const navigate = useNavigate();

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [data, setData] = useState([]);
  const [onGo, setOnGo] = useState(false);
  const [tenantId, setTenantId] = useState(null);
  const [checkedServices, setCheckedServices] = useState({});
  const [existingMappings, setExistingMappings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (tenantId) {
      getOnGoData();
    }
  }, [tenantId]);

  const getOnGoData = async () => {
    try {
      setLoading(true);
      // Fetch the active services
      let servicePayload = {
        modelName: "service_masters",
        whereCondition: { is_active: 1 },
      };
      const serviceResponse = await fetchData("getMasterList", servicePayload);

      if (serviceResponse.data) {
        setData(serviceResponse.data);

        // Fetch the tenant's service mappings
        let mappingPayload = {
          modelName: "tenant_service_mappings",
          whereCondition: { tenant_id: tenantId, is_active: 1 },
        };
        const mappingResponse = await fetchData(
          "getMasterList",
          mappingPayload
        );

        if (mappingResponse.data) {
          const initialCheckedState = {};
          serviceResponse.data.forEach((service) => {
            const mapping = mappingResponse.data.find(
              (mapping) => mapping.service_id == service.id
            );
            initialCheckedState[service.id] = mapping
              ? mapping.map == 1
              : false;
          });
          setExistingMappings(mappingResponse.data);
          setCheckedServices(initialCheckedState);
          setOnGo(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }

      const responseData = await fetchData("getMasterList", payload);
      const dropdownResponseData = await dropdownData(
        responseData.data,
        "tenant_company_name"
      );

      setTenantlist(dropdownResponseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedOption) => {
    setSelectedTenant(selectedOption);
    setTenantId(selectedOption?.value);
  };

  const handleCheckboxChange = (serviceId) => {
    setCheckedServices({
      ...checkedServices,
      [serviceId]: !checkedServices[serviceId],
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const multipleData = Object.keys(checkedServices).map((serviceId) => {
      const existingMapping = existingMappings.find(
        (mapping) => mapping.service_id === parseInt(serviceId)
      );
      return {
        id: existingMapping ? existingMapping.id : undefined, // Include the ID if existing mapping is found
        tenant_id: tenantId,
        service_id: parseInt(serviceId),
        map: checkedServices[serviceId] ? 1 : 0,
        is_active: 1,
      };
    });

    var payload = {
      modelName: "tenant_service_mappings",
      inputData: {
        multipleData: multipleData,
      },
    };

    const responseData = await fetchData("createAndUpdateMaster", payload);

    if (responseData.code === 1) {
      setOnGo(false);
      toast.success("Service Map saved successfully!");
      setTimeout(() => {
        navigate(0);
      }, 2000);
    } else {
      toast.error("Error While saving Service Map!");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="SERVICE MAPPING"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    <Row
                    // className="justify-content-center align-items-center"
                    >
                      <Col md={4}>
                        <Label className="form-label">Tenant</Label>
                        <Select
                          value={selectedTenant}
                          onChange={handleSelectTenantChange}
                          options={tenantlist}
                          classNamePrefix="select2-selection"
                          noOptionsMessage={() => "no data found.."}
                          maxMenuHeight={200}
                          isClearable
                        />
                      </Col>
                      {/* <Col className="mt-4">
                        <Button
                          onClick={handleOnGo}
                          color="primary"
                          className="waves-effect waves-light me-1">
                          Go
                        </Button>
                      </Col> */}
                    </Row>
                    {loading ? (
                      <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <Spinner color="primary" />
                      </div>
                    ) : (
                      onGo && (
                        <Row className="mt-5">
                          <Col xl="12" className="mt-1">
                            <div>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Service</th>
                                    <th>Map</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data && data.length > 0 ? (
                                    data.map((item) => (
                                      <tr key={item.id}>
                                        <td>{item.service_name}</td>
                                        <td>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={
                                              checkedServices[item.id] || false
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(item.id)
                                            }
                                            id={`tenantCheck${item.id}`}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="2">No data available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>

                          <Col className="d-flex justify-content-center align-items-center mt-4">
                            <Button
                              color="primary"
                              type="submit"
                              className="waves-effect waves-light me-1">
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      )
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceMapping;
