import React, { useMemo, useState, useEffect, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, academics } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";
const breadcrumbItems = [];

const ContentBannerList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    try {
      const payload = {
        modelName: "academy_content_banner_images",
        whereCondition: {
          type: 2,
          is_deleted: 0,
        },
      };
      const responseData = await fetchData("getMasterList", payload, academics);
      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching data
    }
  };

  const handleClickAdd = () => {
    navigate("/content-banner-create");
  };

  const handleEditClick = (id) => {
    navigate(`/content-banner-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;

    try {
      const onConfirm = async () => {
        const payload = {
          id: id,
          modelName: "academy_content_banner_images",
          inputData: {
            is_deleted: 1,
          },
        };

        const responseData = await fetchData(
          "createAndUpdateMaster",
          payload,
          academics
        );
        if (responseData && responseData.code === 1) {
          toast.success("Content Banner Images Deleted!");
          getData();
        } else {
          toast.error("Error while deleting the Content Banner Images.");
        }
        isToastOpenRef.current = false;
      };

      const onCancel = () => {
        toast.info("Deletion cancelled");
        isToastOpenRef.current = false;
      };

      const onClose = () => {
        isToastOpenRef.current = false;
      };
      showConfirmationToast(
        "Are you sure you want to delete this Content Banner Images?",
        onConfirm,
        onCancel,
        onClose
      );
    } catch (error) {
      console.error("error", error);
      isToastOpenRef.current = false;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },
      {
        Header: "Redirection url",
        accessor: (row) =>
          row.redirection_url && row.redirection_url
            ? row.redirection_url
            : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "300px" }}>{value}</div>,
      },
      {
        Header: "Sequence",
        accessor: (row) => (row.sequence && row.sequence ? row.sequence : "-"),
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (
          <div style={{ width: "100px" }}>
            {value === 0 ? "In Active" : "Active"}
          </div>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        width: 100,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Content Banner List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Content Banner
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContentBannerList;
