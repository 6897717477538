import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  downloadBlobData,
  dropdownData,
  fetchData,
  image_url,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";

const FeatureActionCreate = ({ router }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [breadcrumbItems] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [servicelist, setServicelist] = useState([]);
  const [serviceError, setServiceError] = useState(false);
  const [IconImageSrc, setIconImageSrc] = useState("");
  const [fetchedData, setFetchedData] = useState(null);
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] =
    useState(false);
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(false);

  const [formData, setFormData] = useState({
    inputData: {
      service_id: "",
      feature_name: "",
      label: "",
      icon: "",
      action: 0,
      is_active: 1,
    },

    uploadImage: {
      imagePath: "",
      folderPath: "",
      field_name: "icon",
    },
  });

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.icon) {
        setIconImageSrc(image_url + data.icon);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      getServiceDropdownData();
      let payload = {
        modelName: "features_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getServiceDropdownData(data.service_id);
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getServiceDropdownData = async (id) => {
    try {
      let payload = {
        modelName: "service_masters",
        whereCondition: { is_active: 1 },
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "service_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedService(element);
            }
          });
        }
        setServicelist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectServiceChange = (selectedService) => {
    setSelectedService(selectedService);
    setServiceError(!selectedService);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        service_id: selectedService ? selectedService.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("checked", checked);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setIconImageSrc(reader.result);
        setIsMediaRemoveAndDownload(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownloadMediaClick = async (mediaFile) => {
    try {
      setMediaImageLoadingData(true);
      const urlSegments = mediaFile.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];
      await downloadBlobData("", lastSegment);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setMediaImageLoadingData(false);
    }
  };

  // handle media remove
  const handleRemoveMediaClick = () => {
    setIconImageSrc("");
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        icon: "",
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    !selectedService ? setServiceError(true) : setServiceError(false);

    if (
      !selectedService ||
      !formData.inputData.feature_name ||
      !formData.inputData.label
    ) {
      return toast.warning("All fields are required!");
    }

    try {
      setLoadingData(true);
      var payload = {
        modelName: "features_masters",
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (id) {
        payload.id = id;
      }
      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Feature saved successfully!");
        navigate("/feature-list");
      } else {
        toast.error("Error While saving Feature!");
        navigate("/feature-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/feature-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE FEATURE ACTION"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Service
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedService}
                                onChange={handleSelectServiceChange}
                                options={servicelist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {serviceError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Service
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Feature Action Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="feature_name"
                                placeholder="Feature action name"
                                type="text"
                                errorMessage="Enter Feature Action Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.feature_name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Feature Action Label
                                <Asterisk />
                              </Label>
                              <AvField
                                name="label"
                                placeholder="Feature Action Label"
                                type="text"
                                errorMessage="Enter Feature Action Label"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.label}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <Label
                              className="form-label"
                              htmlFor="validationCustom01">
                              Feature Action Icon
                            </Label>
                            <div className="input-group">
                              <input
                                name="imagePath"
                                type="file"
                                className="form-control"
                                id="customFile"
                                onChange={handleFileChange}
                              />
                            </div>
                            {IconImageSrc && (
                              <Col md="6" className="mt-1">
                                <div className="mb-3 d-flex">
                                  <img
                                    src={IconImageSrc}
                                    alt={"icon image"}
                                    width="120px"
                                    height="100px"
                                    style={{
                                      border: ".5px solid gray",
                                      borderRadius: "5px",
                                    }}
                                  />
                                  {!isMediaRemoveAndDownload && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "10px",
                                      }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "15px",
                                        }}>
                                        {loadingMediaImageData ? (
                                          <LoaderDownloadMedia />
                                        ) : (
                                          <img
                                            src={downloadIcon}
                                            alt="download"
                                            width="30px"
                                            height="30px"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDownloadMediaClick(
                                                IconImageSrc
                                              )
                                            }
                                          />
                                        )}
                                        <img
                                          src={removeIcon}
                                          alt="remove"
                                          width="30px"
                                          height="30px"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={handleRemoveMediaClick}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg="4">
                            <div
                              style={{ display: "inline-flex", gap: "14px" }}>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.inputData.action}
                                  id="invalidCheck"
                                  name="action"
                                  checked={formData.inputData.action}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck">
                                  Action
                                </label>
                              </div>

                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.inputData.is_active}
                                  id="invalidCheck"
                                  name="is_active"
                                  checked={formData.inputData.is_active}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck">
                                  Is Active
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FeatureActionCreate;
