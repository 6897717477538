import React, { useEffect, useMemo, useRef, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, academics } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";

const breadcrumbItems = [];

const ContentPostList = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [contentChannelTypeId, setContentChannelTypeId] = useState([]);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, [searchKeyword, contentChannelTypeId]);

  // get listing data
  const getData = async () => {
    try {
      let payload = "";
      let apiUrl = "";
      if (contentChannelTypeId.length > 0) {
        payload = {
          type: 2,
          channel_id: contentChannelTypeId,
          search: {
            field_name: ["title", "subtitle"],
            searchKeyword: searchKeyword,
          },
        };
        apiUrl = "postlistbytype";
      } else {
        payload = {
          modelName: "academic_channel_posts",
          whereCondition: {
            is_deleted: 0,
            type: 2,
          },
          search: {
            field_name: ["title", "subtitle"],
            searchKeyword: searchKeyword,
          },
        };
        apiUrl = "getMasterList";
      }

      const responseData = await fetchData(apiUrl, payload, academics);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });
      const sortedData = responseData.data.sort((a, b) => b.index - a.index);
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/content-post-create");
  };

  const handleEditClick = (id) => {
    navigate(`/content-post-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;

    const onConfirm = async () => {
      const payload = {
        type: "Post",
        id: id,
        confirmDelete: true,
      };
      const responseData = await fetchData(
        "academicContentDelete",
        payload,
        academics
      );
      if (responseData && responseData.code === 1) {
        toast.success(responseData.message);
        getData(); // Refres  h data after deletion
      } else {
        toast.error("Error while deleting the channel.");
      }
      isToastOpenRef.current = false;
    };

    const onCancel = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;
    };

    const onClose = () => {
      isToastOpenRef.current = false;
    };

    showConfirmationToast(
      "Are you sure you want to delete this Post?",
      onConfirm,
      onCancel,
      onClose
    );
  };

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  const getContentChannelTypeId = (channeId) => {
    setContentChannelTypeId(channeId);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },

      {
        Header: "Post Title",
        accessor: (row) => (row.title && row.title ? row.title : "-"),
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "250px" }}>{value}</div>,
      },
      {
        Header: "Type",
        accessor: (row) =>
          row.media.type && row.media.type ? row.media.type : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "100px" }}>{value}</div>,
      },
      {
        Header: "Speaker Name",
        accessor: (row) =>
          row.speaker_name && row.speaker_name ? row.speaker_name : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "300px" }}>{value}</div>,
      },
      {
        Header: "Version",
        accessor: (row) => (row.version && row.version ? row.version : "-"),
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (
          <div style={{ width: "100px" }}>
            {value === 0 ? "In Active" : "Active"}
          </div>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Content Post List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Content Post
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onSearchInputData={getSearchInputData}
                onContentChannelDropselect={getContentChannelTypeId}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  );
};

export default ContentPostList;
