import React, { useEffect, useMemo, useState, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, academics } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";
import { showNormalToast } from "../../../services/toaster/NormalToaster";

const AcademicModuleList = () => {
  const breadcrumbItems = [];
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [fileData, setFileData] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [channelTypeId, setChannelTypeId] = useState([]);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, [searchKeyword, channelTypeId]);

  const getData = async () => {
    try {
      let payload = "";
      let apiName = "";
      if (channelTypeId && channelTypeId.length > 0) {
        payload = {
          type: 1,
          channel_id: channelTypeId,
          search: {
            field_name: ["title", "speaker_name"],
            searchKeyword: searchKeyword,
          },
        };
        apiName = "modulelistbytype";
      } else {
        payload = {
          modelName: "academic_channel_modules",
          whereCondition: {
            is_deleted: 0,
          },
        };
        apiName = "getMasterList";
      }

      const responseData = await fetchData(apiName, payload, academics);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      let sortedData = responseData.data.sort((a, b) => b.index - a.index);

      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/academic-module-create");
  };

  const handleEditClick = (id) => {
    navigate(`/academic-module-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;
    const payload = {
      type: "Module",
      id: id,
      confirmDelete: false,
    };
    const responseData = await fetchData(
      "academicContentDelete",
      payload,
      academics
    );

    if (responseData && responseData.code == 1) {
      if (responseData.isHavingModuleAndPost) {
        // true
        showNormalToast(responseData.message);
        isToastOpenRef.current = false;
      } else {
        const onConfirm = async () => {
          const payload = {
            type: "Module",
            id: id,
            confirmDelete: true,
          };
          const responseData1 = await fetchData(
            "academicContentDelete",
            payload,
            academics
          );
          if (responseData1 && responseData1.code === 1) {
            toast.success(responseData1.message);
            getData();
          } else {
            toast.error("Error while deleting the channel.");
          }
          isToastOpenRef.current = false;
        };

        const onCancel = () => {
          toast.info("Deletion cancelled");
          isToastOpenRef.current = false;
        };

        const onClose = () => {
          isToastOpenRef.current = false;
        };

        showConfirmationToast(
          responseData.message,
          onConfirm,
          onCancel,
          onClose
        );
      }
    } else {
      toast.error("Error while deleting the Module.");
    }
  };

  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const validFileExtensions = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (file && !validFileExtensions.includes(file.type)) {
      toast.error("Wrong file type! Please choose an Excel file");
      fileInputRef.current.value = "";
    } else {
      setFileData(file);
      storeFileData(file);
    }
  };

  const storeFileData = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file?.name);

    const responseData = await fetchData(
      "academicbulkupload",
      formData,
      academics
    );
    console.log("submating  Selected formData :==> ", responseData);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },
      {
        Header: "Module Title",
        accessor: (row) => (row.title && row.title ? row.title : "-"),
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "300px" }}>{value}</div>,
      },
      {
        Header: "Speaker Name",
        accessor: (row) =>
          row.speaker_name && row.speaker_name ? row.speaker_name : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "200px" }}>{value}</div>,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (
          <div style={{ width: "100px" }}>
            {value === 0 ? "In Active" : "Active"}
          </div>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div style={{ width: "150px" }}>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  const getChannelTypeId = (channeId) => {
    setChannelTypeId(channeId);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Academic Module List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Academic Module
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onSearchInputData={getSearchInputData}
                onChannelDropselect={getChannelTypeId}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
      {/* <ToastContainer />  */}
    </React.Fragment>
  );
};

export default AcademicModuleList;
